import styled from 'styled-components'
import media from 'styled-media-query'
import {color} from '../../constants/theme'

export const Container = styled.div`
    padding-top: 100px;
`
export const ContentBlock = styled.div`
    margin: 0 auto 70px;
    width: 80%;

    .list_title{
        margin-bottom: 25px;
        margin-top: 50px;
        font-weight: 700;
    }

    h2{
        color: #1C2640;
        margin-bottom: 30px;
    }

    button{
        color: #F6B800;
        font-size: 18px;
        margin-bottom: 50px;
        display: flex;
        align-items: center;

        span{
            height: 20px;
            width: 2px;
            background: #F6B800;
            position: relative;
            border-radius: 5px;
            transform: rotate(-90deg);
            margin-right: 15px;

            :after, :before{
                content: "";
                position: absolute;
                width: 2px;
                height: 10px;
                background: #F6B800;
                top: -1px;
                border-radius: 5px;
            }

            :after{
                transform: rotate(40deg);
                right: 3px;
            }
            :before{
                transform: rotate(-40deg);
                left: 3px;
            }
        }
    }
`
// export const Title = styled.div`
//     font-size: 28px;
//     margin-bottom: 30px;
// `
export const Text = styled.div`
    
    p{
        margin-bottom: 20px;
    }
`
export const List = styled.div`
    padding-left: 25px;
    
    p{
        margin-bottom: 12px;
        position: relative;

        :before{
            content: '';
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            border: 2px solid #F6B800;
            top: 50%;
            left: -25px;
            transform: translateY(-50%);
        }
    }
`