import React, { useState, useEffect } from "react"
import { Container, DocumentsItems, MobileVersion } from "./styles"
import { SectionTitle } from "../titles"
import Permit from '../../images/doc.png'
import Invitation from '../../images/invitation.png'
import Audit from '../../images/audit.png'
import Document from '../../images/document1.png'
import Visa from '../../images/visa.png'
import Card from '../../images/card1.png'
import PermitS from '../../images/card3.png'

const Documents = () => {
    return (
        <Container>
            <SectionTitle>Bądź legalnie z nami!</SectionTitle>
            <DocumentsItems>
                <div className="documents_item">
                    <img alt='img' src={Document} />
                    <div>Zezwolenia na pobyt rezydenta </div>
                </div>
                <div className="documents_item">
                    <img alt='img' src={PermitS} />
                    <div>Pobyt czasowy</div>
                </div>
                <div className="documents_item">
                    <img alt='img' src={Permit} />
                    <div>Pobyt stały</div>
                </div>
                <div className="documents_item">
                    <img alt='img' src={Audit} />
                    <div>Audyt legalności</div>
                </div>
                <div className="documents_item">
                    <img alt='img' src={Invitation} />
                    <div>Zaproszenia</div>
                </div>
                <div className="documents_item">
                    <img alt='img' src={Visa} />
                    <div>Wizy</div>
                </div>
                <div className="documents_item">
                    <img alt='img' src={Card} />
                    <div>Karta Polaka</div>
                </div>
            </DocumentsItems>
            <MobileVersion>
                <div className="documents_items">
                    <div className="documents_item">
                        <img alt='img' src={Document} />
                        <div>Zezwolenia na pobyt rezydenta </div>
                    </div>
                    <div className="documents_item">
                        <img alt='img' src={PermitS} />
                        <div>Pobyt czasowy</div>
                    </div>
                </div>
                <div className="documents_items">
                    <div className="documents_item">
                        <img alt='img' src={Permit} />
                        <div>Pobyt stały</div>
                    </div>
                    <div className="documents_item">
                        <img alt='img' src={Audit} />
                        <div>Audyt legalności</div>
                    </div>
                </div>
                <div className="documents_items">
                    <div className="documents_item">
                        <img alt='img' src={Invitation} />
                        <div>Zaproszenia</div>
                    </div>
                    <div className="documents_item">
                        <img alt='img' src={Visa} />
                        <div>Wizy</div>
                    </div>
                </div>
                <div className="documents_item last_item">
                    <img alt='img' src={Card} />
                    <div>Karta Polaka</div>
                </div>
            </MobileVersion>
        </Container>
    )
}

export default Documents