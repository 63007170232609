import React from "react"
import { FunctionContainer, Table } from "./styles"
import { SectionTitle } from '../titles/index'
import Slide from 'react-reveal/Slide'
import screen from '../../images/screen.png'
import clients from '../../images/clients.png'
import term from '../../images/term.png'
import partners from '../../images/partners.png'
import document from '../../images/document1.png'
import presentation from '../../images/presentation.png'
import worker from '../../images/worker_rating.png'
import onboarding from '../../images/onboarding.png'
import review from '../../images/review.png'

const Function = ({ myRef }) => {
    return (
        <FunctionContainer ref={myRef}>
            <div className="img"><img src={screen} alt='screen' /></div>
            <Slide right>
                <div className="text">
                    <SectionTitle>Funkcjonalności naszej platformy</SectionTitle>
                    <div className="text_items">
                        <div><img src={clients} alt='img' /><p>rekrutacja masowa: 50 os. i więcej</p></div>
                        <div><img src={onboarding} alt='img' /><p>onbording</p></div>
                        <div><img src={term} alt='img' /><p>zarządzanie czasem pracy</p></div>
                        <div><img src={document} alt='img' /><p>raporty</p></div>
                        <div><img src={worker} alt='img' /><p>ocena pracownika</p></div>
                        <div><img src={presentation} alt='img' /><p>szkolenia (e-lerning)</p></div>
                        <div><img src={review} alt='img' /><p>ocena Agencji Pracy</p></div>
                        <div><img src={partners} alt='img' /><p>współpraca z Agencjami Pracy (zarządzanie projektem)</p></div>
                    </div>
                </div>
            </Slide>
        </FunctionContainer>
    )
}

export default Function