import styled from 'styled-components'
import media from 'styled-media-query'

export const SectionTitle = styled.h2`
    color: #fff;
    font-size: 32px;
    font-family: 'Montserrat-Bold', sans-serif;
    width: fit-content;
    letter-spacing: 0.8px;
    line-height: 1.2;
    position: relative;
    z-index:1;

    ${media.lessThan('768px')`
        font-size: 24px;
    `}
`
