import React, { useState, useEffect } from "react"
import { Container } from "./styles"
import { NavLink, Link } from "react-router-dom"
import eaclogo from '../../images/eac_logo.png'

const MobileMenu = ({ isMenuOpen, executeScroll, myRef, myRef2, myRef3 }) => {
    return (
        <Container style={{ transform: isMenuOpen ? `translateX(0)` : `translateX(100%)` }}>
            <NavLink to='/legalization' exact>Legalizacja</NavLink>
            <div className="link" onClick={() => executeScroll(myRef)}>Usługi</div>
            <div className="link" onClick={() => executeScroll(myRef2)}>Blog</div>
            <div className="link" onClick={() => executeScroll(myRef3)}>Kontakt</div>
            <a href='https://formikapraca.eu/vacancies' target='_blanck'>Dla pracownika</a>
            <NavLink to='/eacsolution' className='eac_logo'><img src={eaclogo} alt="eac_solution"/></NavLink>
        </Container>
    )
}

export default MobileMenu