import React, { useState, useEffect } from "react"
import { Container, Text } from "./styles"
import Slide from 'react-reveal/Slide'
import img from '../../images/about_leg.webp'

const AboutLegalization = ({ executeScroll, myRef }) => {
    return (
        <Container>
            <Slide left>
                <div className="img"> <img src={img} alt='img' /></div>
            </Slide>
            <Slide right>
                <div className="text">
                    <span className="line" />
                    <p>Formika od ponad 12 lat specjalizuje się zarówno w międzynarodowym, jak i krajowym pośrednictwie pracy. Zajmujemy się profesjonalnym doradztwem zawodowym, pośrednictwem i leasingiem pracowniczym. W przeciągu kilku lat zbudowaliśmy prężnie działający dział legalizacji pobytu. Nasz zespół składa się ze specjalistów, którzy przez lata zdobywali doświadczenie w tym zakresie. Zapraszamy do współpracy wszystkich, których interesuje całkowicie legalne zatrudnienie obcokrajowca, pomagamy załatwić wszelkie formalności związane z pobytem w naszym kraju.</p>
                </div>
            </Slide>
        </Container>
    )
}

export default AboutLegalization