import React, { useState, useRef } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Posts, PostsIntro, InfoBlock } from "./styles"
import { Link } from 'react-router-dom'
import { blogData } from "./data"
import Header from '../header/headerBlog'
import Contact from '../contact/contact'
import MobileMenu from '../mobileMenu/mobileMenuBlog'
import Slide from 'react-reveal/Slide'
import ScrollToTop from "../scroll"

const BlogPage = ({eacPage}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const myRef3 = useRef(null)

    function executeScroll(ref) {
        window.scroll({
            top: ref.current.offsetTop,
            behavior: "smooth",
        })
        setIsMenuOpen(false)
    }

    return (
        <>
            <ScrollToTop setIsMenuOpen={setIsMenuOpen}/>
            <Header executeScroll={executeScroll} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} myRef3={myRef3} />
            <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} executeScroll={executeScroll} myRef3={myRef3}></MobileMenu>
            <Slide top>
                <PostsIntro>
                    <h2>Bądź z nami na <span>bieżąco</span></h2>
                </PostsIntro>
            </Slide>
            <Posts>
                {blogData.map((data, key) => {
                    let link = "/article/" + data.id
                    return (
                        <InfoBlock>
                            <div className='img_block'>
                                <img src={data.img} alt='img' />
                                <span className='date'>{data.date}</span>
                            </div>
                            <div className='info'>
                                <h3>{data.name}</h3>
                                <p>{data.desc}</p>
                                <Link to={link}>
                                    <button>
                                        Więcej
                                        <svg width="13px" height="10px" viewBox="0 0 13 10">
                                            <path d="M1,5 L11,5"></path>
                                            <polyline points="8 1 12 5 8 9"></polyline>
                                        </svg>
                                    </button>
                                </Link>
                            </div>
                        </InfoBlock>
                    )
                })}
            </Posts>
            <Contact about={true} myRef3={myRef3} eacPage={false}/>
        </>
    )
}

export default BlogPage