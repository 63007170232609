import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.footer`
    background: #141A32;
    color: #fff;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .copyright{
        font-size: 10px;
    }
`
export const Social = styled.div`
    margin-bottom: 10px;

    a{
        margin-right: 10px;

        :last-child{
            margin-right: 0;
            margin-left: 2px;
        }
    }
    
    img{
        width: 23px;
    }
`