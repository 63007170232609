import React, { useRef } from "react"
import { Container, AdvItems, AdvItem } from "./styles"
import { SectionTitle } from "../titles"
import worker from '../../images/worker.png'
import cooperation from '../../images/cooperation.png'
import savings from '../../images/savings.png'
import expert from '../../images/expert.png'
import Fade from 'react-reveal/Fade'

const Advantages = ({ myRef }) => {
    return (
        <Fade>
            <Container ref={myRef}>
                <SectionTitle>Co zyskasz współpracując z nami?</SectionTitle>
                <AdvItems>
                    <AdvItem>
                        <img src={worker} alt='worker_img' />
                        <h6>Rzetelni pracownicy</h6>
                        <p>Zyskasz profesjonalne pozyskiwanie talentów oraz dostęp do bazy ponad 1500 kandydatów gotowych do podjęcia długofalowej współpracy w kraju i zagranicą.</p>
                    </AdvItem>
                    <AdvItem>
                        <img src={cooperation} alt='worker_img' />
                        <h6>Elastyczna współpraca</h6>
                        <p>
                            Dostosujemy ofertę i zakres działań do Twoich potrzeb. Realizujemy zlecenia na terenie kraju i Europy. Specjalizujemy się w dostarczeniu niewykfalifikowanych pracowników.</p>
                    </AdvItem>
                </AdvItems>
                <AdvItems>
                    <AdvItem>
                        <img src={savings} alt='worker_img' />
                        <h6>Oszczędności</h6>
                        <p>Oszczędzisz czas i zasoby w dziale HR. Zyskasz możliwość szybkiej zdalnej i bezpośredniej rekrutacji pracowników w kraju i zagranicą.</p>
                    </AdvItem>
                    <AdvItem>
                        <img src={expert} alt='worker_img' />
                        <h6>Współpraca z ekspertami</h6>
                        <p>Zyskasz dostęp do naszej bazy wiedzy oraz ekspertów z którymi współpracujemy. Posiadamy przedstawicielstwa w Ukrainie, Białorusi, Rumunii, Indiach, Nepalu i na Tajwanie.</p>
                    </AdvItem>
                </AdvItems>
            </Container>
        </Fade>
    )
}

export default Advantages