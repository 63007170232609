import React, {useRef, useState} from 'react'
import { Container } from "./styles"
import Header from '../header/header'
import Achivements from '../achivements/achivements'
import CooperationStages from '../cooperationStages/cooperationStages'
import Partners from '../partners/partners'
import OfferText from '../offer/offer'
import Contact from '../contact/contact'
import AboutIntro from '../aboutIntro/aboutIntro'
import Blog from '../blog/blog'
import Advantages from '../ourAdvantages/advantages'
import MobileMenu from '../mobileMenu/mobileMenu'
import ScrollToTop from '../scroll'

const AboutPage = ({eacPage}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const myRef = useRef(null)
    const myRef2 = useRef(null)
    const myRef3 = useRef(null)

    function executeScroll(ref) {
        window.scroll({
            top: ref.current.offsetTop,
            behavior: "smooth",
        })
        setIsMenuOpen(false)
    }

    return (
        <Container>
            <ScrollToTop setIsMenuOpen={setIsMenuOpen}/>
            <Header executeScroll={executeScroll} myRef={myRef} myRef2={myRef2} myRef3={myRef3} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
            <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} executeScroll={executeScroll} myRef={myRef} myRef2={myRef2} myRef3={myRef3}/>
            <AboutIntro executeScroll={executeScroll} myRef={myRef}/>
            <Advantages myRef={myRef}/>
            <CooperationStages />
            <Achivements />
            <OfferText executeScroll={executeScroll} myRef3={myRef3}/>
            <Partners />
            <Blog myRef2={myRef2}/>
            <Contact myRef3={myRef3} about={true} eacPage={false}/>
        </Container>
    )
}

export default AboutPage