import React from 'react'
import { Container, AchivementsItem, Number, Text, Img } from "./styles"
import CountUp from 'react-countup'
import clients from '../../images/clients.png'
import partners from '../../images/partners.png'
import term from '../../images/term.png'
import workers from '../../images/workers.png'

const Achivements = () => {
    return (
        <Container>
            <div className='container_inner'>
                <AchivementsItem>
                    <Img src={partners} alt='img' />
                    <Number>
                        <CountUp
                            start={0} end={70}
                            delay={0}
                            enableScrollSpy={true}
                            scrollSpyOnce={true}
                            prefix="> "
                            className='number'
                        >
                            {({ countUpRef }) => (
                                <>
                                    <div ref={countUpRef} />
                                </>
                            )}
                        </CountUp>
                    </Number>
                    <Text>partnerów</Text>
                </AchivementsItem>
                <AchivementsItem>
                    <Img src={clients} alt='img' />
                    <Number>
                        <CountUp
                            start={0} end={100}
                            delay={0}
                            enableScrollSpy={true}
                            scrollSpyOnce={true}
                        >
                            {({ countUpRef }) => (
                                <>
                                    <div ref={countUpRef} />
                                </>
                            )}
                        </CountUp>
                    </Number>
                    <Text>zadowolonych klientów</Text>
                </AchivementsItem>
                <AchivementsItem>
                    <Img src={term} alt='img' />
                    <Number>
                        <CountUp
                            start={0} end={10}
                            delay={0}
                            enableScrollSpy={true}
                            scrollSpyOnce={true}
                            prefix="~ "
                        >
                            {({ countUpRef }) => (
                                <>
                                    <div ref={countUpRef} />
                                </>
                            )}
                        </CountUp>
                    </Number>
                    <Text>lat na rynku</Text>
                </AchivementsItem>
                <AchivementsItem>
                    <Img src={workers} alt='img' />
                    <Number>
                        <CountUp
                            start={0} end={2600}
                            delay={0}
                            enableScrollSpy={true}
                            scrollSpyOnce={true}
                            prefix="> "
                        >
                            {({ countUpRef }) => (
                                <>
                                    <div ref={countUpRef} />
                                </>
                            )}
                        </CountUp>
                    </Number>
                    <Text>zatrudnionych pracowników</Text>
                </AchivementsItem>
            </div>
        </Container>
    )
}

export default Achivements