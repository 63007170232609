import React, {useRef, useState} from 'react'
import Header from '../header/headerEac'
import Contact from '../contact/contact'
import MobileMenu from '../mobileMenu/mobileMenuEac'
import ScrollToTop from '../scroll'
import Problems from './problems'
import Intro from './eacIntro'
import Function from './function'
import Features from './eacFeatures'
import Scheme from './scheme'
import TargetGroup from './targetGroup'

const EacPage = ({about, eacPage}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const myRef = useRef(null)
    const myRef2 = useRef(null)
    const myRef3 = useRef(null)
    const myRef4 = useRef(null)

    function executeScroll(ref) {
        window.scroll({
            top: ref.current.offsetTop,
            behavior: "smooth",
        })
        setIsMenuOpen(false)
    }

    return (
        <>
            <ScrollToTop setIsMenuOpen={setIsMenuOpen}/>
            <Header executeScroll={executeScroll} myRef={myRef} myRef2={myRef2} myRef3={myRef3} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
            <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} executeScroll={executeScroll} myRef={myRef} myRef2={myRef2} myRef3={myRef3}/>
            <Intro myRef4={myRef4} executeScroll={executeScroll}/>
            <Problems myRef4={myRef4}/>
            <Function myRef={myRef}/>
            <Scheme />
            <Features myRef2={myRef2}/>
            <TargetGroup />
            <Contact myRef3={myRef3} about={true} eacPage={true}/>
        </>
    )
}

export default EacPage