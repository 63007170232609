import React, { useRef, useEffect, useState } from "react"
import { Container, Logo } from "./styles"
import { NavLink, Link } from 'react-router-dom'
import logo from '../../images/logo.png'
import BurgerBtn from '../buttons/burger'

const Header = ({executeScroll, myRef, myRef2, myRef3, isMenuOpen, setIsMenuOpen}) => {
  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    console.log(window.scrollY)
    if (window.scrollY >= 100) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    window.addEventListener("scroll", changeBackground)
  })

  return (
    <Container style={{ background: !navbar ? `transparent` : `#141A32` }}>
      <Link to='/'><Logo><span>For</span><img style={{ background: !navbar ? `#1C2640` : `#141A32` }} src={logo} alt='m' /><span>ika</span></Logo></Link>
      <nav>
        <NavLink to='/aboutformika' exact>O firmie</NavLink>
        <div className="link" onClick={() => executeScroll(myRef)}>Usługi</div>
        <div className="link" onClick={() => executeScroll(myRef2)}>Cennik</div>
        <div className="link" onClick={() => executeScroll(myRef3)}>Kontakt</div>
        <a href='https://formikapraca.eu/vacancies' target='_blanck'>Dla pracownika</a>
      </nav>
      <BurgerBtn isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </Container>
  )
}

export default Header