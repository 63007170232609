import React, { useState, useEffect } from "react"
import { Container } from "./styles"
import { NavLink, Link } from "react-router-dom"

const MobileMenuLeg = ({ executeScroll, myRef, myRef2, myRef3, isMenuOpen }) => {
    return (
        <Container style={{ transform: isMenuOpen ? `translateX(0)` : `translateX(100%)` }}>
            <NavLink to='/aboutformika' exact>O firmie</NavLink>
            <div className="link" onClick={() => executeScroll(myRef)}>Usługi</div>
            <div className="link" onClick={() => executeScroll(myRef2)}>Cennik</div>
            <div className="link" onClick={() => executeScroll(myRef3)}>Kontakt</div>
            <a href='https://formikapraca.eu/vacancies' target='_blanck'>Dla pracownika</a>
        </Container>
    )
}

export default MobileMenuLeg