import React, { useState, useEffect } from "react"
import { Container } from "./styles"
import { NavLink } from "react-router-dom"

const MobileMenuBlog = ({ executeScroll, myRef3, isMenuOpen }) => {
    return (
        <Container style={{ transform: isMenuOpen ? `translateX(0)` : `translateX(100%)` }}>
            <NavLink to='/aboutformika' exact>O firmie</NavLink>
            <NavLink to='/legalization' exact>Legalizacja</NavLink>
            <div className="link" onClick={() => executeScroll(myRef3)}>Kontakt</div>
            <a href='https://formikapraca.eu/vacancies' target='_blanck'>Dla pracownika</a>
        </Container>
    )
}

export default MobileMenuBlog