import React from "react"
import { TargetContainer } from "./styles"
import Slide from 'react-reveal/Slide'
import { SectionTitle } from "../titles"
import corporation from '../../images/corporation.png'
import agency from '../../images/agency.png'
import warehouse from '../../images/warehouse.png'

const TargetGroup = () => {
    return (
        <TargetContainer>
            <SectionTitle>Dla kogo?</SectionTitle>
            <div className="flex">
                <div className="block">
                    <img src={corporation} alt='img' />
                    <p>Duże firmy w szczególności o strukturze rozproszonej (centrala i kilka oddziałów )</p>
                    <span>ponad 4000 w Polsce</span>
                </div>
                <div className="block">
                    <img src={agency} alt='img' />
                    <p>Agencje Zatrudnienia/Agencje Pracy Tymczasowej </p>
                    <span>8830 w Polsce</span>
                </div>
                <div className="block">
                    <img src={warehouse} alt='img' />
                    <p>Jednoodziałowe zakłady zatrudniające dużą liczbę pracowników agencyjnych lub tymczasowych</p>
                </div>
            </div>
        </TargetContainer>
    )
}

export default TargetGroup