import React, {useRef} from "react"
import { Container, Text, Img } from "./styles"
import img from '../../images/blog.png'
import { Link } from 'react-router-dom'

const Blog = ({myRef2}) => {
    return (
        <Container ref={myRef2}>
            <Text>
                <p>
                    Zapraszamy do odwiedzenia naszego <Link to='/blog'>bloga!</Link>
                </p>
                <p>
                    Tu będziemy publikować najnowsze informacje i analizy dotyczące rynku, a także dzielić się naszą wiedzą i doświadczeniem.
                    Bądź z nami, aby być na bieżąco z najważniejszymi wydarzeniami i trendami.
                </p>
                <a href='https://www.linkedin.com/in/piotrrafalski/' target='_blank' className="button_link">
                    Zapraszamy również do subskrybcji nas na LinkedIn
                    <svg width="13px" height="10px" viewBox="0 0 13 10">
                        <path d="M1,5 L11,5"></path>
                        <polyline points="8 1 12 5 8 9"></polyline>
                    </svg>
                </a>
            </Text>
            <Img src={img} alt='img' />
        </Container>
    )
}

export default Blog