import React, { useState, useEffect } from "react"
import { Container } from "./styles"
import { NavLink, Link } from "react-router-dom"
import eaclogo from '../../images/eac_logo.png'

const MobileMenu = ({ isMenuOpen, executeScroll, myRef, myRef2, myRef3 }) => {
    return (
        <Container style={{ transform: isMenuOpen ? `translateX(0)` : `translateX(100%)` }}>
            <NavLink to='/aboutformika' exact>O firmie</NavLink>
            <div className="link" onClick={() => executeScroll(myRef)}>Funkcjanalności</div>
            <div className="link" onClick={() => executeScroll(myRef2)}>Korzyści</div>
            <div className="link" onClick={() => executeScroll(myRef3)}>Kontakt</div>
        </Container>
    )
}

export default MobileMenu