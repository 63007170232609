import styled from 'styled-components'
import media from 'styled-media-query'
import { color } from '../../constants/theme'
import bg from '../../images/blog_intro1.webp'

export const PostsIntro = styled.div`
    background-color: ${color('text')};
    height: 40vh;
    background-image: url(${bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 100px;

    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, rgba(28,38,64,1) 40%, rgba(255,255,255,0));
    }

    h2{
        color: ${color('white')};
        position: relative;
        z-index: 1;
        font-size: 42px;
        font-family: 'Garet-Book', sans-serif;
        margin-top: 60px;

        span{
            color: ${color('accent')};
        }

        ${media.lessThan('768px')`
            font-size: 24px;
        `}
    }

    ${media.lessThan('768px')`
        padding: 0 20px 0;
        justify-content: center;
        :after{
            background: ${color('text')};
            opacity: 0.8;
        }
    `}
`
export const Posts = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;
    padding: 150px 0 100px;
    width: 85%;
    margin: 0 auto;

    ${media.lessThan('1310px')`
        width: 90%;
        grid-gap: 2.5rem;
    `}

    ${media.lessThan('1200px')`
        grid-gap: 1.5rem;
    `}

    ${media.lessThan('1024px')`
        grid-template-columns: 1fr 1fr;
    `}

    ${media.lessThan('768px')`
        grid-template-columns: 1fr;
        padding-top: 105px;
    `}
`
export const InfoBlock = styled.div`
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: ${color('white')};
    position: relative;

    .img_block{
        position: relative;
        
        img{
            object-fit: cover; 
            width: 100%;
            height: 200px;
        } 
        .date{
            position: absolute;
            bottom: 15px;
            left: 20px;
            color: #fff;
            background: rgba(0,0,0,0.6);
            padding: 5px 30px;
            border-radius: 1rem;
            font-size: 14px;
        }
    }

    .info{
        padding: 20px 20px 25px 20px;

        a{
            position: absolute;
            bottom: 20px;
        }
    }

    h3{
        font-size: 18px;
        color: rgba(0,0,0,0.9);
        transform: translateY(-4px); 
        border-top: none;
    }

    p{
        color: rgba(25, 32, 59, 0.8);
        margin-top: 10px;
        line-height: 24px;
        font-size: 14px;
        margin-bottom: 55px;
    }

    button{
        background: ${color('accent')};
        padding: 10px 20px;
        border-radius: 10px;
        color: #fff;
        font-family: 'Garet-Book', sans-serif;
        font-size: 14px;
        letter-spacing: 0.8px;
        position: relative;
        margin-top: 20px;
        transition: all 0.1s linear;

        svg{
            position: relative;
            top: 0;
            margin-left: 10px;
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke: ${color('white')};
            stroke-width: 1;
            transform: translateX(-5px);
            transition: all .3s ease;
        }

        :hover{
            transform: scale(1.05);
        }
    }
`
// Article Page style

export const ArticleIntro = styled.div`
    height: 40vh;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 60px 0;
    text-align: center;

    p{
        color: ${color('accent')};
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        font-weight: 800;
        font-size: 14px;

        img{
            width: 20px;
            margin-right: 10px;
        }
    }

    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
    }

    .hidden_btn{
        width: 45px;

        ${media.lessThan('768px')`
            display: none;
        `}
    }

    h2{
        position: relative;
        z-index: 1;
        color: ${color('white')};
        font-size: 28px; 
        margin: 0 auto 20px;

        ${media.lessThan('1024px')`
            font-size: 22px;
        `}
    }

    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: ${color('text')};
        opacity: 0.75;
    }

    a{
        width: 45px;
        height: 45px;
        background: ${color('accent')};
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        ${media.lessThan('768px')`
            display: none;
        `}

        :after{
            content: '';
            position: absolute;
            width: 65px;
            height: 65px;
            background: ${color('accent')};
            opacity: 0.3;
            top: 50%;
            right: 50%;
            border-radius: 50%;
            transform: translate(50%, -50%);
            z-index: -1;
            transition: all 0.3s ease;
        }

        .arrow{
            position: relative;
            width: 25px;
            height: 25px;
            
            :after, :before{
                content: '';
                position: absolute;
                width: 71%;
                height: 2px;
                background: ${color('white')};
                border-radius: 10px;
                right: 22%;
            }

            :after{
                transform: rotate(40deg) translateX(6px);
                top: 11%;
            }

            :before{
                transform: rotate(-40deg) translateX(5px);
                top: 79%;
            }
        }

        .left{
            transform: rotate(-180deg);
        }

        :hover{
            :after{
                transform: translate(50%, -50%) scale(1.1);
            }  
        }
    }

    ${media.lessThan('1024px')`
        padding: 60px 30px 0;
    `}
`
export const Text = styled.div`
    width: 50%;
    margin: 0 auto;
    padding: 100px 0;

    p{
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 1.6;

        ${media.lessThan('768px')`
            font-size: 14px;
        `}
    }

    h6{
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 15px;
    }

    a{
        font-weight: 800;
    }

    .links{
        margin-bottom: 30px;
        font-size: 14px;
        color: ${color('accent')};
        font-weight: 900;
    }

    .back_btn{
        font-size: 14px;
        color: ${color('accent')};
        margin-bottom: 40px;
        letter-spacing: 1.5px;

        svg{
            position: relative;
            top: 0;
            margin-left: 10px;
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke: ${color('accent')};
            stroke-width: 2;
            transform: translateX(-5px) rotate(180deg);
            transition: all .3s ease;
        } 
        
        ${media.lessThan('768px')`
            margin-bottom: 25px;
        `}
    }

    ${media.lessThan('1200px')`
        width: 60%;
    `}

    ${media.lessThan('1024px')`
        width: 80%;
        padding: 80px 0;
    `}
`
export const Buttons = styled.div`
    justify-content: space-between;
    margin-bottom: 40px;
    display: none;
    
    a{
        width: 45px;
        height: 45px;
        background: ${color('accent')};
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        :after{
            content: '';
            position: absolute;
            width: 65px;
            height: 65px;
            background: ${color('accent')};
            opacity: 0.3;
            top: 50%;
            right: 50%;
            border-radius: 50%;
            transform: translate(50%, -50%);
            z-index: -1;
        }

        .arrow{
            position: relative;
            width: 25px;
            height: 25px;
            
            :after, :before{
                content: '';
                position: absolute;
                width: 71%;
                height: 2px;
                background: ${color('white')};
                border-radius: 10px;
            }

            :after{
                transform: rotate(40deg) translateX(6px);
                top: 11%;
            }

            :before{
                transform: rotate(-40deg) translateX(5px);
                top: 79%;
            }
        }

        .left{
            transform: rotate(-180deg);
        }
    }

    ${media.lessThan('768px')`
        display: flex;
    `}
`