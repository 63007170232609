import styled from 'styled-components'
import media from 'styled-media-query'
import { color } from '../../constants/theme'

export const Container = styled.div`
    padding: 120px 0 170px;

    h2{
        color: ${color('text')}; 
        margin: 0 auto 80px;
    }

    ${media.lessThan('768px')`
        padding: 50px 30px;

        h2{
            margin-bottom: 40px;
        }
    `}
`
export const AdvItems = styled.div`
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;

    :last-child{
        margin-top: 30px;
    }

    ${media.lessThan('1100px')`
        width: 80%;
    `}

    ${media.lessThan('768px')`
        flex-direction: column;
        width: 100%;

        :last-child{
            margin-top: 0;
        }
    `}
`
export const AdvItem = styled.div`
    width: 45%;

    h6{
        font-size: 18px;
        margin-bottom: 7px;
    }

    p{
        font-size: 14px;
    }

    img{
        width: 80px;
        margin-bottom: 20px;
    }

    ${media.lessThan('768px')`
        width: 100%;
        margin-bottom: 25px;

        img{
            width: 70px;
            margin-bottom: 7px; 
        }
    `}
`