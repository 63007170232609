import styled from 'styled-components'
import {color} from '../../constants/theme'
import media from 'styled-media-query'

export const Container = styled.section`
    padding: 100px 0 100px;

    ${media.lessThan('768px')`
        padding: 70px 30px 30px;
    `}
`
export const ServicesItems = styled.div`
    width: 50%;
    margin: 0 auto;

    .img{
        width: 20%;
        position: relative;

        img{
            width: 80%;

            ${media.lessThan('500px')`
                width: 100%;
            `}
        }

        .number{
            position: absolute;
            font-size: 140px;
            font-family: Montserrat-Bold, sans-serif;
            font-weight: 900;
            // color: rgb(255, 251, 239);
            color: ${color('accent')};
            opacity: 0.7;
            z-index: 1;
            top: 50%;
            left: 60%;
            transform: translate(-50%, -50%);

            ${media.lessThan('675px')`
                font-size: 110px;
            `}

            ${media.lessThan('500px')`
                font-size: 90px;
                left: 75%;
            `}
        }
    }

    .content{
        width: 70%;

        .title{
            margin-bottom: 25px;
            font-size: 19px;
            font-weight: 800;
        }
        
        ${media.lessThan('768px')`
            width: 75%;
        `}
    }

    ${media.lessThan('1280px')`
        width: 70%;
    `}

    ${media.lessThan('1100px')`
        width: 80%;
    `}

    ${media.lessThan('768px')`
        width: 100%;
    `}
`
export const ServicesItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 40px;
`