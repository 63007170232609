import React, { useState, useEffect } from "react"
import { Container } from "./styles"
import img from '../../images/about_intro1.webp'

const OfferText = ({executeScroll, myRef3}) => {
    return (
        <Container>
            <div className="img"> <img src={img} alt='img' /></div>
            <div className="text">
                <span className="line" />
                <p>Oferta naszej Agencji Pracy Tymczasowej skierowana jest do pracodawców szukających elastycznego i kompetentnego 
                wsparcia w zakresie zatrudnienia pracowników. Nasz zespół składa się 
                z doświadczonych profesjonalistów, którzy zapewniają wysoką jakość usług i indywidualne podejście do każdego klienta. 
                Zapraszamy do skorzystania z naszych usług i dołączenia do grona zadowolonych klientów.</p>
                <button onClick={() => executeScroll(myRef3)}>Kontakt</button>
            </div>
        </Container>
    )
}

export default OfferText