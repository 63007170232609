import styled from 'styled-components'
import media from 'styled-media-query'
import {color} from '../../constants/theme'

export const Container = styled.div`
    padding: 140px 0;
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto;

    .text{
        width: 45%;
        font-size: 15px;
        margin-left: 50px;

        ${media.lessThan('1024px')`
            width: 50%;
            margin-left: 30px;
        `}

        ${media.lessThan('768px')`
            width: 100%;
            margin-left: 0;
            text-align: center;
        `}
    }

    .img{
        width: 45%;
        position: relative;
       
        img{
            width: 100%;
            display: block;
        }

        :after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0.70;
        }

        ${media.lessThan('768px')`
            display: none;
        `}
    }

    button{
        width: 140px;
        margin-top: 30px;
        background: linear-gradient(90deg, rgb(230, 81, 92) 0%, rgb(240, 136, 94) 42%, rgb(251, 175, 67) 100%);
        color: ${color('white')};
        padding: 12px 0;
        font-family: 'Garet-Book', sans-serif;
        font-size: 14px;
        border-radius: 20px;
        transition: all 0.4s ease;

        :hover{
            transform:  translate(2px, -2px);
        }

        ${media.lessThan('500px')`
            width: 100%;
            margin-top: 40px;
        `}
    }

    .line{
        width: 90px;
        height: 4px;
        background: ${color('accent')};
        margin-bottom: 20px;
        display: block;

        ${media.lessThan('768px')`
            margin: 0 auto 25px;
        `}
    }

    ${media.lessThan('1100px')`
        width: 90%;
        padding: 80px 0 100px;
    `}

    ${media.lessThan('768px')`
        flex-direction: column;
        width: auto;
        padding: 60px 20px 90px;
    `}
`