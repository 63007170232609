import get from 'lodash/get';

export const theme = {
    colors: {
        // main: 'linear-gradient(to bottom right, #19203B, #5270FA)',
        main: '#1C2640',
        backgroundLight: '#EBEFF8',
        accent: '#F6B800', 
        white: '#fff',
        text: '#19203B', 
    },
};

export function themeVar(path) {
    return () => get(theme, path);
}

export function color(colorKey) {
    return themeVar(`colors.${colorKey}`);
}