import styled from 'styled-components'
import media from 'styled-media-query'
import { color } from '../../constants/theme'

export const Container = styled.div`
    width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto;
    padding: 190px 0 180px;

    ${media.lessThan('1380px')`
        width: 80%;
    `}

    ${media.lessThan('1300px')`
        width: 90%;
    `}

    ${media.lessThan('1024px')`
        width: 100%;
    `}

    ${media.lessThan('768px')`
        width: auto;
        padding: 100px 20px 100px;
    `}
`
export const Text = styled.div`
    background: ${color('white')};
    padding: 50px;
    height: fit-content;
    width: 45%;
    margin-left: 50px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    a{
        font-weight: 800;
        transition: all 0.3s ease;

        :hover{
            color: ${color('accent')}; 
        }
    }

    p{
        :first-child{
            margin-bottom: 10px;
        }
    }
    
    .button_link{
        font-size: 14px;
        margin-top: 30px;
        display: inline-block;
        color: ${color('accent')}; 
        transition: all 0.3s ease;
        letter-spacing: 0.6px;

        :hover{
            transform: translateX(3px);
        }
    }

    svg{
        position: relative;
        top: 0;
        margin-left: 10px;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: ${color('accent')};
        stroke-width: 2;
        transform: translateX(-5px);
        transition: all .3s ease;
    }

    ${media.lessThan('768px')`
        margin-left: 0;
        width: 100%;
        padding: 20px;
    `}
`
export const Img = styled.img`
    width: 50%;
    position: absolute;
    right: 6%;
    z-index: -1;

    ${media.lessThan('1550px')`
        width: 55%;
    `}

    ${media.lessThan('1470px')`
        width: 60%;
    `}

    ${media.lessThan('768px')`
        display: none;
    `}
`