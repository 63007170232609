import styled from 'styled-components'
import media from 'styled-media-query'
import {color} from '../../constants/theme'

export const Container = styled.div`
    padding: 0 80px 80px;
    position: relative;

    h2{
        color: ${color('text')};
        margin-bottom: 50px;
    }

    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 45%;
        bottom: 20px;
        left: 0;
        background: ${color('text')};
        z-index: -1;
    }

    ${media.lessThan('1260px')`
        padding: 0 40px 80px;
    `}

    ${media.lessThan('1024px')`
        h2{
            margin: 0 auto 50px;
        }
        :after{
            height: 0;
        }
    `}

    ${media.lessThan('768px')`
        padding: 0 20px 80px;
    `}
`
export const DocumentsItems = styled.div`
    display: flex;
    justify-content: space-between;

    .documents_item{
        background: ${color('white')};
        padding: 20px 7px 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 800;
        text-align: center;
        margin-right: 15px;
        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;

        :last-child{
            margin-right: 0;
        }
    }

    img{
        width: 50px;
        margin-bottom: 30px;
        transition: all 0.3s ease;

        :hover{
            transform: scale(1.1);
        }
    }

    ${media.lessThan('1024px')`
        display: none;
    `}
`
export const MobileVersion = styled.div`
    flex-direction: column;
    align-items: center;
    display: none;

    .documents_items{
        display: flex;  
        width: 80%;
        margin: 0 auto 20px;
        justify-content: space-between;
    }

    .documents_item{
        background: ${color('white')};
        padding: 20px 7px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 800;
        text-align: center;
        margin: 0 7px;
        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
        width: 50%;
    }

    .last_item{
        width: 38%;
    }

    img{
        width: 50px;
        margin-bottom: 30px;
    }

    ${media.lessThan('660px')`
        .documents_items{
            width: 100%;
        }
        .last_item{
            width: 45%;
        }
    `}

    ${media.lessThan('1024px')`
        display: flex;
    `}
`