import React from "react"
import { Container, Line, Triangles, Mobile } from "./styles"
import { SectionTitle } from '../titles/index'
import Slide from 'react-reveal/Slide'

const CooperationStages = () => {
    return (
        <Container>
            <SectionTitle>Jak działamy</SectionTitle>
            <Line />
            <Triangles>
                <Slide top>
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <span>Krok 1</span><p>Analiza potrzeb klienta</p>
                    </div>
                </Slide>
                <Slide top delay={100}>
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <span>Krok 2</span><p>Podpisanie umowy</p>
                    </div>
                </Slide>
                <Slide top delay={200}>
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <span>Krok 3</span><p>Kampania rekrutacyjna</p>
                    </div>
                </Slide>
                <Slide top delay={300}>
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <span>Krok 4</span><p>Zatrudnienie i legalizacja pracowników</p>
                    </div>
                </Slide>
                <Slide top delay={400}>
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <span>Krok 5</span><p>Koordynacja współpracy</p>
                    </div>
                </Slide>
                <Slide top delay={500}>
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <span>Krok 6</span><p>Rozliczenie comiesięczne</p>
                    </div>
                </Slide>
            </Triangles>
            <Mobile>
                <div className="line" />
                <div className="text">
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <div>
                            <span>Krok 1</span><p>Analiza potrzeb klienta</p>
                        </div>
                    </div>
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <div>
                            <span>Krok 2</span><p>Podpisanie umowy</p>
                        </div>
                    </div>
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <div>
                            <span>Krok 3</span><p>Kampania rekrutacyjna</p>
                        </div>
                    </div>
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <div>
                            <span>Krok 4</span><p>Zatrudnienie i legalizacja pracowników</p>
                        </div>
                    </div>
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <div>
                            <span>Krok 5</span><p>Koordynacja współpracy</p>
                        </div>
                    </div>
                    <div className="triangles_item">
                        <div className="triangle"></div>
                        <div>
                            <span>Krok 6</span><p>Rozliczenie comiesięczne</p>
                        </div>
                    </div>
                </div>
            </Mobile>
        </Container>
    )
}

export default CooperationStages