import React, { useRef } from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Container } from './styles'
import MainPage from '../mainPage/mainPage'
import AboutPage from '../aboutPage/aboutPage'
import LegalizationPage from '../legalizationPage/legalizationPage'
import BlogPage from '../blogPage/blogPage'
import ArticlePage from '../blogPage/postPage'
import EacPage from '../eacPage/eacPage'
import OutsourcingPage from '../outsourcingPage/outsourcingPage'

const PageRoutes = () => {
    return (
        <Router>
            <Container>
                <Routes>
                    <Route exact path="/" element={<MainPage />} />
                    <Route path="/aboutformika" element={<AboutPage />} />
                    <Route path="/legalization" element={<LegalizationPage />} />
                    <Route path="/blog" element={<BlogPage />} />
                    <Route path="/article/:id" element={<ArticlePage />} />
                    <Route path="/eacsolution" element={<EacPage />} />
                    <Route path="/outsourcing" element={<OutsourcingPage />} />
                </Routes>
            </Container>
        </Router>
    )
}

export default PageRoutes