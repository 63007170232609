import styled from "styled-components"
import media from 'styled-media-query'
import {color} from '../../constants/theme'

export const Container = styled.div`
    // margin: 80px 0 170px;

    h2{
        margin: 0 auto 40px;
        color: ${color('text')};

        // ${media.lessThan('1024px')`
        //     margin: 0 auto;
        // `}
    }

    .slick-slider{
        width: 85%;
        margin: 0 auto;
    }

    .slick-track{
        display: flex;
        align-items: center;
    }

    .slick-slide{
        width: 10% !important;

        div{
            display: flex;
            justify-content: center;
        }

        img{
            width: 80% !important;
            height: 100%;

            ${media.lessThan('920px')`
                width: 92% !important;
            `}
        }
    }

    .slick-prev, .slick-next{
        position: absolute;

        ::before{
            color: ${color('accent')}; 
            font-size: 32px;
        }

        ${media.lessThan('1024px')`
            display: none !important;
        `}
    }
    .slick-prev{
        left: -50px !important;
    }
    .slick-next{
        right: -30px !important;
    }
`
// export const Logos = styled.div`
//     width: 85%;
//     margin: 0 auto;

//     .line{
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//     }
//     .line:last-child{
//         width: 90%;
//         margin: 0 auto;
//     }
//     img{
//         width: 14%;
//         height: fit-content;
//     }
// `