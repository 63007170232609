import React from "react"
import { Container, Logos } from "./styles"
import { SectionTitle } from '../titles/index'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import logo1 from '../../images/logo_partners/hjort.png'
import logo2 from '../../images/logo_partners/forte.png'
import logo3 from '../../images/logo_partners/exide.png'
import logo4 from '../../images/logo_partners/hortex.png'
import logo5 from '../../images/logo_partners/forest.png'
import logo6 from '../../images/logo_partners/linea.png'
import logo7 from '../../images/logo_partners/polargos.png'
import logo8 from '../../images/logo_partners/id.png'
import logo9 from '../../images/logo_partners/tago.png'
import logo10 from '../../images/logo_partners/krynica.png'
import logo11 from '../../images/logo_partners/lpp.png'
import logo12 from '../../images/logo_partners/glassolutions.png'
import logo13 from '../../images/logo_partners/jurajska.png'
import logo14 from '../../images/logo_partners/rhenus.png'
import logo15 from '../../images/logo_partners/woda.png'
import logo16 from '../../images/logo_partners/bakalland.png'
import logo17 from '../../images/logo_partners/unitop.png'

const PartnersLogo = () => {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
        ]
    }

    return (
        <Container>
            <SectionTitle>Zaufali nam min.</SectionTitle>
            {/* <Logos>
                <div className="line">
                    <img src={logo1} alt='logo' />
                    <img src={logo2} alt='logo' />
                    <img src={logo3} alt='logo' />
                    <img src={logo4} alt='logo' />
                    <img src={logo5} alt='logo' />
                    <img src={logo6} alt='logo' />
                </div>
                <div className="line">
                    <img src={logo7} alt='logo' />
                    <img src={logo8} alt='logo' />
                    <img src={logo12} alt='logo' />
                    <img src={logo10} alt='logo' />
                    <img src={logo11} alt='logo' />
                    <img src={logo9} alt='logo' />
                </div>
                <div className="line">
                    <img src={logo13} alt='logo' />
                    <img src={logo14} alt='logo' />
                    <img src={logo15} alt='logo' />
                    <img src={logo16} alt='logo' />
                    <img src={logo17} alt='logo' />
                </div>
            </Logos> */}
            <Slider {...settings}>
                <img src={logo1} alt='logo' />
                <img src={logo2} alt='logo' />
                <img src={logo3} alt='logo' />
                <img src={logo4} alt='logo' />
                <img src={logo5} alt='logo' />
                <img src={logo6} alt='logo' />
                <img src={logo7} alt='logo' />
                <img src={logo8} alt='logo' />
                <img src={logo9} alt='logo' />
                <img src={logo10} alt='logo' />
                <img src={logo11} alt='logo' />
                <img src={logo12} alt='logo' />
                <img src={logo13} alt='logo' />
                <img src={logo14} alt='logo' />
                <img src={logo15} alt='logo' />
                <img src={logo16} alt='logo' />
                <img src={logo17} alt='logo' />
            </Slider>
        </Container>
    )
}

export default PartnersLogo