import styled from 'styled-components'
import media from 'styled-media-query'
import {color} from '../../constants/theme'
import bg from '../../images/leg.webp'

export const Container = styled.div`
    padding: 120px 0 120px;
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    p{
        width: 40%;
        display: block;
        margin-left: 50px;
        position: relative;
        z-index: 1;

        ${media.lessThan('1024px')`
            width: 60%;
            margin-left: 30px;
        `}

        ${media.lessThan('730px')`
            width: auto;
            margin-left: 0;
            text-align: center;
            color: #fff;
        `}
    }

    .img{
        width: 45%;
        position: relative;
       
        img{
            width: 100%;
            display: block;
        }

        :after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.3);
            opacity: 0.70;
        }

        ${media.lessThan('730px')`
            display: none;
        `}
    }

    .line{
        width: 90px;
        height: 4px;
        background: ${color('accent')};
        margin-bottom: 20px;
        display: block;
        position: relative;
        z-index: 1;

        ${media.lessThan('730px')`
            margin: 0 auto 20px;
        `}
    }

    ${media.lessThan('1200px')`
        width: 90%;
        padding: 100px 0 120px;
    `}

    ${media.lessThan('900px')`
        padding: 30px 0 100px;
    `}

    ${media.lessThan('730px')`
        width: auto;
        padding: 60px 30px;
        background-image: url(${bg});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        margin-bottom: 90px;

        :after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: ${color('text')};
            opacity: 0.75;
          }
    `}
`