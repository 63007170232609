import React from "react"
import { Container, ServicesItems, ServicesItem } from './styles'
import figure from '../../images/figure.png'
import Slide from 'react-reveal/Slide'

const Services = ({ myRef }) => {
    return (
        <Container ref={myRef}>
            <ServicesItems>
                <Slide left>
                    <ServicesItem>
                        <div className="img">
                            <div className="number">1</div>
                            <img alt='img' src={figure} />
                        </div>
                        <div className="content">
                            <div className="title">Kompleksowa obsługa legalizacji pobytu i zatrudnienia cudzoziemców</div>
                            <div className="text">Z nami możesz więcej. Chcesz zatrudnić pracownika? My zajemy resztą.</div>
                        </div>
                    </ServicesItem>
                </Slide>
                <Slide right>
                    <ServicesItem>
                        <div className="content">
                            <div className="title">Audyt legalności</div>
                            <div className="text">Usługa audytu legalności to sprawdzone rozwiązanie, które obejmuje kompleksową weryfikację istniejących procedur oraz działań w zakresie zatrudniania cudzoziemców w Twojej firmie</div>
                        </div>
                        <div className="img">
                            <div className="number">2</div>
                            <img alt='img' src={figure} />
                        </div>
                    </ServicesItem>
                </Slide>
                <Slide left>
                    <ServicesItem>
                        <div className="img">
                            <div className="number">3</div>
                            <img alt='img' src={figure} />
                        </div>
                        <div className="content">
                            <div className="title">Szkolenia</div>
                            <div className="text">Przeprowadzamy szkolenia z zakresu legalnego zatrudniania cudzoziemców z całego świata. Jesteśmy na bieżąco z Polskim prawodawstwem oraz posiadamy unikatową wiedzę, dajemy gwarancję w przekazie rzetelnej i sprawdzonej informacji.</div>
                        </div>
                    </ServicesItem>
                </Slide>
                <Slide right>
                    <ServicesItem>
                        <div className="content">
                            <div className="title">Outsourcing Legalizacji</div>
                            <div className="text">Zapewniamy kompleksowy outsourcing legalizacji pobytu i pracy, oferta skierowana do wszystkich firm zatrudniających cudzoziemców oraz agencji pracy tymczasowych. Reprezentujemy klientów przed urzędami wojewódzkimi i urzędami pracy.</div>
                        </div>
                        <div className="img">
                            <div className="number">4</div>
                            <img alt='img' src={figure} />
                        </div>
                    </ServicesItem>
                </Slide>
                <Slide left>
                    <ServicesItem>
                        <div className="img">
                            <div className="number">5</div>
                            <img alt='img' src={figure} />
                        </div>
                        <div className="content">
                            <div className="title">Konsultacje dla pracodawców i pracowników</div>
                            <div className="text">Masz pytania? Odpowiemy na wszystkie i rozwiejemy Twoje wątpliwości jak legalnie zatrudniać i być legalnie zatrudnionym.</div>
                        </div>
                    </ServicesItem>
                </Slide>
            </ServicesItems>
        </Container >
    )
}

export default Services