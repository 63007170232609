import styled from 'styled-components'
import media from 'styled-media-query'
import { color } from '../../constants/theme'
import bg from '../../images/achiv_bg.png'
import bg_intro from '../../images/eacIntro.png'

//Intro

export const IntroContainer = styled.div`
    background-color: ${color('text')};
    height: 100vh;
    background-image: url(${bg_intro});
    background-size: cover;
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 75px;

    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, rgba(28,38,64,1) 50%, rgba(255,255,255,0));
    }

    .content{
        position: relative;
        z-index: 1;
        color: ${color('white')};
        width: 49%;
        line-height: 1.6;

        img{
            width: 170px;
        }

        h2{
            font-size: 38px;
            margin: 60px 0 20px;
            line-height: 1.2;
            color: ${color('white')};
        }

        p{
            font-size: 18px;
            width: 80%;
        }
    }

    ${media.lessThan('1300px')`
        .content{
            width: 55%;
        }
    `}

    ${media.lessThan('1200px')`
        .content {
            width: 75%;
            img{
                width: 120px;
            }
            h2{
                margin: 40px 0 20px;
            }
        }
    `}

    ${media.lessThan('900px')`
        padding: 0 50px;
        .content{
            width: auto;

            h2{
                font-size: 28px;
            }
        }  
    `}

    ${media.lessThan('768px')`
        padding: 0 30px;
        :after{
            background: ${color('text')};
            opacity: 0.8;
        }
        .content{
            text-align: center;

            img{
                margin: 0 auto;
            }

            p{
                width: auto;
                margin: 0 auto;
            }
        }
    `}
    
    button{
        position: relative;
        margin-top: 30px;
        padding: 19px 22px;
        transition: all .2s ease;
        display: flex;
        align-items: center;
        color: ${color('white')};

        ${media.lessThan('768px')`
            margin: 30px auto 0;
        `}
    
        :before{
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          border-radius: 28px;
          background: ${color('accent')};
          opacity: 0.9;
          width: 56px;
          height: 56px;
          transition: all 0.3s ease;
        }
        span{
          position: relative;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: .2em;
          vertical-align: middle;
        }
        svg{
          position: relative;
          top: 0;
          margin-left: 10px;
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke: ${color('white')};
          stroke-width: 2;
          transform: translateX(-5px);
          transition: all .3s ease;
        }
        &:hover{
          &:before{
            width: 100%;
            background: rgba(#000,1);
          }
          svg{
            transform: translateX(0);
          }
        }
        &:active{
          transform: scale(.96);}
    
          ${media.lessThan('400px')`
            margin-top: 20px;
          `}
        }
`
//Problems

export const ProblemsContainer = styled.div`
    margin: 120px auto 60px;
    width: 80%;

    h2{
        color: ${color('text')};
        margin-bottom: 50px;
    }
    
    .grid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 35px;
    }

    .text{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        
        span{
            font-size: 90px;
            font-family: Montserrat-Bold, sans-serif;
            font-weight: 900;
            color: rgb(246, 184, 0);
            opacity: 0.5;
            display: block;
            margin-right: 17px;

        }
    }

    ${media.lessThan('1200px')`
        width: 90%;
    `} 

    ${media.lessThan('1024px')`
        margin: 90px auto 0;
        width: auto;
        padding: 0 30px;

        .grid{
            grid-template-columns: 1fr 1fr;
        }
        
        h2{
            margin-bottom: 30px;
        }
    `} 

    ${media.lessThan('600px')`
        .grid{
            grid-template-columns: 1fr;
        }
    `} 
`

//Function

export const FunctionContainer = styled.div`
    margin: 0 auto;
    padding: 100px 0 150px;
    display: flex;
    justify-content: space-between;
    transform: translateX(-200px);
    
    .img{
        width: 53%;

        img{
            width: 100%;
        }
    }

    .text{
        width: 45%;
        transform: translateX(60px);
    }

    .text_items{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        row-gap: 15px;
        width: 95%;

       div{
        display: flex;
        align-items: center;
        // border: 2px solid rgba(246, 184, 0, 0.3);
        border: 2px solid rgba(25, 32, 59, 0.2);
        border-radius: 4px;
        padding: 10px 15px;
        font-size: 16px;
        font-weight: 800;
        
        img{
            width: 38px;
            margin-right: 15px;
        }
       }
    }

    h2{
        color: ${color('text')};
        margin-bottom: 50px;
    }

    ${media.lessThan('1490px')`
        .text-items{
            width: 100%;
        }
        .text{
            width: 50%;
        }
    `}

    ${media.lessThan('1430px')`
        align-items: center;
        width: 110%;
    `}
    
    ${media.lessThan('1100px')`
        width: 113%;
    `}

    ${media.lessThan('960px')`
        .img{display: none};
        transform: translate(0px,0px);
        padding: 60px 30px 100px;
        width: auto;

        .text{
            width: 100%;
            transform: translate(0px,0px);
        }
        .text_items{
            width: 100%;
        }
    `}
    ${media.lessThan('600px')`
        .text_items{
            grid-template-columns: 1fr;
        }
    `}
`
export const Table = styled.table`
    width: 100%;
    margin-top: 50px;
    border-collapse: collapse;
    font-size: 14px;

    th, td{
        padding: 9px;
    }

    th{
        padding-bottom: 15px;
        font-size: 16px;
    }

    tr:first-child{
        background: transparent !important;
    }

    tr:nth-child(odd) {
        background-color: rgba(246, 184, 0, 0.05);
    }

    td{
        border-right: 1.5px solid ${color('accent')};
        width: 10%;
        position: relative;

        :last-child{
            border: none;
        }

        :first-child{
            width: 30%;
        }
    }

    .cross {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        width: 20px;
        height: 20px;

        :before, :after {
            position: absolute;
            left: 8.5px;
            top: 1px;
            content: ' ';
            height: 17px;
            width: 2px;
            background-color: #FF3767;
            border-radius: 5px;
          }
          :before {
            transform: rotate(45deg);
          }
          :after {
            transform: rotate(-45deg);
          }
    }

    .check-mark {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        width: 17px;
        height: 17px;

        :before, :after{
            position: absolute;
            left: 0;
            background-color: #336699;
            content: "";
            transform: translateX(6px) rotate(-45deg);
            transform-origin: left bottom;
            border-radius: 5px;
        }

        :before {
            top: 51%;
            height: 50%;
            width: 2px;
        }
        
        :after {
            bottom: 0;
            height: 2px;
            width: 100%;
        }
    }   
`
//Scheme

export const SchemeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h2{
        color: ${color('text')};
        margin-bottom: 70px;
    }

    .flex{
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: 0 auto;
    }

    .img{
        width: 55%;
        display: flex;
        align-items: center;

        img{
            width: 100%;
        }
    }

    .text{
        width: 38%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    p{
        margin-bottom: 15px;
        font-size: 15px;
    }

    span{
        font-weight: 800;
        background: ${color('accent')};
        font-size: 16px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
    }
    
    ${media.lessThan('1280px')`
        .flex{
            width: 90%;
        }
        .text{
            width: 42%;
        }
    `}
    
    ${media.lessThan('1024px')`
        .flex{
            flex-direction: column;
        }
        h2{
            margin-bottom: 30px;
        }
        .text{
            width: 80%;
            margin: 0 auto;
        }
        .img{
            width: 70%;
            margin: 30px auto 0;
        }
    `}
    ${media.lessThan('768px')`
        padding: 0 20px;

        .text, .img, .flex{
            width: auto;
        }
    `}
`
//Values

export const ValuesContainer = styled.div`
    width: 75%;
    margin: 0 auto;
    font-size: 15px;

    h6{
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 25px;
    }

    .content{
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .text{
            width: 45%;
        }

    }

    .icon_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        border: 1px solid #EAE7E3;
        width: 22%;
        padding: 10px 20px;
        margin-left: 200px;

        img{
            width: 65px;
            margin-bottom: 15px;
        }
    }

    .second{
        margin-left: 100px;
    }

    .flex{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`
//Features

export const FeaturesContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 70px 0;
    color: ${color('white')};
    font-size: 14px;
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    margin: 150px 0 120px;
    position: relative;

    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.2);
    }

    .block{
        // background: rgba(25, 32, 59, 1);
        background: #fff;
        width: 30%;
        padding: 50px;
        position: relative;
        color: ${color('text')};
        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
        z-index: 1;

        :first-child{
            margin-right: 50px;

            :after{
                content: 'Bez platformy';
            }
        }
    }
    
    .title{
        margin-bottom: 30px;
        font-size: 20px;
    }

    .feature{
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;

        :last-child{
            margin-bottom: 0;
        }

        p{
            width: 70%;
        }

        img{
            width: 50px;
            margin-left: 7px;
        }

        span{
            color: ${color('accent')};
            border: 2px solid ${color('accent')};
            border-radius: 4px;
            padding: 3px 7px;
            font-weight: 800;
        }

        .number{
            font-size: 26px;
            font-weight: 800;
            border: none;
            padding: 0;
        }
    }

    ${media.lessThan('1390px')`
        .block{
            width: 35%;

            :first-child{
                margin-right: 30px;
            }
        }
    `}

    ${media.lessThan('1024px')`
        flex-direction: column;
        margin: 100px 0 90px;
        padding: 40px 30px;

        .block{
            width: auto;

            :first-child{
                margin: 0 0 30px 0;
            }
        }
    `}

    ${media.lessThan('600px')`
        .block{
            padding: 20px;
        }
        .feature p{
            width: 100%;
        }
    `}
`
//Target Group

export const TargetContainer = styled.div`
    width: 70%;
    margin: 0 auto 130px;

    .flex{
        display: flex;
        margin-top: 50px;
    }

    h2{
        margin-bottom: 70px;
        color: ${color('text')};
    }

    .block{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1;
        background: #fff;
        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
        margin-right: 30px;
        padding: 25px 30px;

        :last-child{
            margin-right: 0;
        }
    }

    img{
        width: 60px;
        margin-bottom: 30px;
    }

    span{
        margin-top: 10px;
        color: ${color('accent')};
        font-weight: 800;
        font-size: 12px;
    }

    ${media.lessThan('1200px')`
        width: 85%;

        .blog{
            padding: 10px;
        }
    `} 
    ${media.lessThan('1060px')`
        width: 90%;
    `}

    ${media.lessThan('850px')`
        margin: 0 auto 70px;
        .flex{
            flex-direction: column;
        }
        h2{
            margin: 0 auto 30px;
        }
        .block{
            margin: 0 0 30px 0;
        }
    `}
`