import styled from 'styled-components'
import { color } from '../../constants/theme'

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    color: ${color('white')};
    height: 100vh;
    background: #141A32;
    width: 55%;
    z-index: 3;
    transition: all .3s linear;

    a, .link{
        margin-bottom: 20px;
        font-size: 20px;
        font-family: 'Garet-Book', sans-serif;
        position: relative;
        
        :last-child{
            margin-bottom: 0;
        }

        :after{
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -4px;
            left: 0;
            background: ${color('accent')};
        }
    }

    .eac_logo{
        img{
            width: 60px;
            margin-top: 20px;
        }
    }
`