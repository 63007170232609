import React from 'react'
import { Container, Title, Logo, Buttons } from "./styles"
import LogoImg from '../../images/logo_main.png'
import { NavLink } from 'react-router-dom'
import Slide from 'react-reveal/Slide'
import EacLogo from '../../images/eac_logo.png'

const Main = () => {
    return (
        <Slide top>
            <Container>
                <div className='logos'>
                    <Logo src={LogoImg} alt='Formika' />
                    <NavLink to='/eacsolution'><img src={EacLogo} alt='aec_solution' className='eac_logo'/></NavLink>
                </div>
                <div className='content'>
                    <Title>Trudne sytuacje <br /> dają nam <span>siłę</span></Title>
                    <Buttons>
                        <NavLink to='/aboutformika'><button>O firmie</button></NavLink>
                        <NavLink to='/legalization'><button className='leg_btn'>Legalizacja</button></NavLink>
                        <NavLink to='/outsourcing'><button className='out_btn'>Outsourcing procesów</button></NavLink>
                    </Buttons>
                </div>
            </Container>
        </Slide>
    )
}

export default Main