import React from "react"
import { IntroContainer } from "./styles"
import Slide from 'react-reveal/Slide'
import logo from '../../images/eac_logo.png'

const EacIntro = ({ executeScroll, myRef4 }) => {
    return (
        <Slide top>
            <IntroContainer>
                <div className="content">
                    <img src={logo} alt='AEC Solution' />
                    <h2>Pierwsza na rynku platforma obsługi procesu rekrutacji masowej </h2>
                    <p>Optymalizuje zarządzanie cyklem rekrutacji i współpracy między Agencjami Pracy a korporacjami</p>
                    <button onClick={() => executeScroll(myRef4)}>
                        <span>Więcej</span>
                        <svg width="13px" height="10px" viewBox="0 0 13 10">
                            <path d="M1,5 L11,5"></path>
                            <polyline points="8 1 12 5 8 9"></polyline>
                        </svg>
                    </button>
                </div>
            </IntroContainer>
        </Slide>
    )
}

export default EacIntro