import React from "react"
import { SchemeContainer } from "./styles"
import { SectionTitle } from "../titles"
import Fade from 'react-reveal/Fade'
import Img from '../../images/scheme.png'

const Scheme = () => {
    return (
        <SchemeContainer>
            <Fade>
                <SectionTitle>Schemat procesu po wdrożeniu platformy</SectionTitle>
                <div className="flex">
                    <div className="text">
                        <p><span></span> Korzystanie z platformy EAC <b>zmniejszy o 90%</b> ilość interakcji pomiędzy korporacjami a Agencjami Pracy. Platforma pozwoli na zarządzanie centralne procesem współpracy oraz zmniejszy w sposób znaczący ilość pracowników zaangażowanych w projekt.</p>
                        <p><span></span> Przepływ informacji pomiędzy firmami będzie <b>ustrukturyzowany, </b><b>przejrzysty</b> oraz <b>mniej czasochłonny</b>. Obie strony unikną niepotrzebnego powielania kontaktów komplikujących współpracę.</p>
                    </div>
                    <div className="img">
                        <img src={Img} alt='scheme' />
                    </div>
                </div>
            </Fade>
        </SchemeContainer>
    )
}

export default Scheme