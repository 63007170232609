import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
    overflow-x: hidden;
    position: relative;
`
export const CloseMobileMenu = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
    display: none;

    ${media.lessThan('768px')`
        display: block;
    `}
`