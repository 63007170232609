import React from "react"
import { ProblemsContainer } from "./styles"
import Fade from 'react-reveal/Fade'
import { SectionTitle } from "../titles"

const Problems = ({myRef4}) => {
    return (
        <ProblemsContainer ref={myRef4}>
            <SectionTitle>Jakie problemy pomożemy rozwiązać?</SectionTitle>
            <Fade>
                <div className="grid">
                    <div className="text"><span>1</span><p>Wolny (utrudniony rozwój) - długi proces wyboru Agencji Zatrudnienia do współpracy i procedowania umów</p></div>
                    <div className="text"><span>2</span><p>Duża dynamika rynku i zmienność potrzeb pracodawców. Potrzeba szybszej reakcji agencji na zapotrzebowania kadrowe</p></div>
                    <div className="text"><span>3</span><p>Zbyt duża liczba osób zaangażowanych we współpracę z Agencją Pracy</p></div>
                    <div className="text"><span>4</span><p>Chaos informacyjny - utrudniona i niejasna komunikacja generująca nieporozumienia i błędy</p></div>
                    <div className="text"><span>5</span><p>Brak automatyzacji procesu, brak wspólnego systemu IT minimalizującego ilość interakcji podczas codziennej współpracy</p></div>
                    <div className="text"><span>6</span><p>Brak kontroli i monitorowania postępów </p></div>
                </div>
            </Fade>
        </ProblemsContainer>
    )
}

export default Problems