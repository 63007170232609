import React, { useState, useEffect, useRef } from "react"
import { ArticleIntro, ReadAlso, InfoBlock, Text, Buttons } from "./styles"
import { Link, useParams } from 'react-router-dom'
import calendar from '../../images/calendar.png'
import { blogData } from "./data"
import Contact from '../contact/contact'
import Header from '../header/headerBlog'
import MobileMenu from '../mobileMenu/mobileMenuBlog'
import Slide from 'react-reveal/Slide'
import ScrollToTop from "../scroll"

const ArticlePage = () => {
    const [article, setArticle] = useState(null)
    const [list, setList] = useState([])
    const [text, setText] = useState([])
    const [allPosts, setAllPosts] = useState([])
    const [posts, setPosts] = useState([])
    const [id, setId] = useState([])
    const [length, setLenght] = useState()
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const myRef3 = useRef(null)

    function executeScroll(ref) {
        window.scroll({
            top: ref.current.offsetTop,
            behavior: "smooth",
        })
        setIsMenuOpen(false)
    }

    const params = useParams()
    const blogId = params.id

    useEffect(() => {
        blogData.forEach(element => {
            if (element.id == blogId) {
                setArticle(element)
                setList(element.listItems)
                setText(element.text)
                setId(element.id)
                setLenght(blogData.length)
                console.log('length', length)
                return
            }
        })
    })

    useEffect(() => {
        // setAllPosts(blogData.sort(() => Math.random() - 0.5))
        setPosts(blogData.slice(1, 8))
    }, [allPosts])

    let linkNext = "/article/" + (id + 1)
    let linkPrevious = "/article/" + (id - 1)

    return (
        article == null ? <div /> :
            <>
                <ScrollToTop setIsMenuOpen={setIsMenuOpen}/>
                <Header executeScroll={executeScroll} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} myRef3={myRef3} />
                <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} executeScroll={executeScroll} myRef3={myRef3}></MobileMenu>
                <Slide top>
                    <ArticleIntro style={{ background: `url(${article.img})` }}>
                        {id <= (length) & id !== 1 ?
                            <Link to={linkPrevious}><span className="arrow left" /></Link> : <div className="hidden_btn" />
                        }
                        <div>
                            <h2>{article.name}</h2>
                            <p className='date'><img src={calendar} alt='img' /> {article.date}</p>
                        </div>
                        {id >= (length) ? <div className="hidden_btn" /> :
                            <Link to={linkNext}><span className="arrow" /></Link>
                        }
                    </ArticleIntro>
                </Slide>
                <Text>
                    <Buttons>
                        {id <= (length) & id !== 1 ?
                            <Link to={linkPrevious}><span className="arrow left" /></Link> : <div className="hidden_btn" />
                        }
                        {id >= (length) ? <div className="hidden_btn" /> :
                            <Link to={linkNext}><span className="arrow" /></Link>
                        }
                    </Buttons>
                    <div className='back_btn'>
                        <Link to='/blog'>
                            <svg width="13px" height="10px" viewBox="0 0 13 10">
                                <path d="M1,5 L11,5"></path>
                                <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                            Wróć do listy artykułów
                        </Link>
                    </div>
                    {text == null ? <div /> :
                        <ul>
                            {text.map((data, key) => {
                                return (
                                    <>{data}</>
                                )
                            })}
                        </ul>
                    }
                    {list == null ? <div /> :
                        <ul>
                            {list.map((data, key) => {
                                return (
                                    <li>{data}</li>
                                )
                            })}
                        </ul>
                    }
                    {article.text2 == null ? <div /> :
                        <ul>
                            {article.text2.map((data, key) => {
                                return (
                                    <p>{data}</p>
                                )
                            })}
                        </ul>
                    }
                    {article.listItems2 == null ? <div /> :
                        <ul>
                            {article.listItems2.map((data, key) => {
                                return (
                                    <li>{data}</li>
                                )
                            })}
                        </ul>
                    }
                </Text>
                <Contact about={true} myRef3={myRef3} />
            </>
    )
}

export default ArticlePage