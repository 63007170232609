import React, {useRef, useState} from 'react'
import { Container } from './styles'
import Header from '../header/headerlegalization'
import Services from '../services/services'
import Documents from '../documents/documents'
import Price from '../price/price'
import Contact from '../contact/contact'
import AditionalServices from '../aditionalServices/aditionalServices'
import CooperationFormsText from '../cooperationForms/cooperationForms'
import LegalizationIntro from '../legalIzationIntro/legalizationIntro'
import MobileMenu from '../mobileMenu/mobileMenuLeg'
import About from '../aboutLeg/aboutLeg'
import ScrollToTop from '../scroll'

const LegalizationPage = ({eacPage}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const myRef = useRef(null)
    const myRef2 = useRef(null)
    const myRef3 = useRef(null)

    function executeScroll(ref) {
        window.scroll({
            top: ref.current.offsetTop,
            behavior: "smooth",
        })
        setIsMenuOpen(false)
    }
    
    return (
        <Container>
            <ScrollToTop setIsMenuOpen={setIsMenuOpen}/>
            <Header executeScroll={executeScroll} myRef={myRef} myRef2={myRef2} myRef3={myRef3} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen}/>
            <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} executeScroll={executeScroll} myRef={myRef} myRef2={myRef2} myRef3={myRef3}/>
            <LegalizationIntro />
            <About />
            <Services myRef={myRef} />
            <Documents />
            <CooperationFormsText />
            <AditionalServices />
            <Price myRef2={myRef2} executeScroll={executeScroll} myRef3={myRef3}/>
            <Contact myRef3={myRef3} about={false} eacPage={false}/>
        </Container>
    )
}

export default LegalizationPage