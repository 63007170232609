import React, { useRef, useState } from 'react'
import { Container, ContentBlock, Title, Text, List } from "./styles"
import { SectionTitle } from '../titles'
import Contact from '../contact/contact'
import { NavLink } from 'react-router-dom'
// import Header from '../header/header'
// import Achivements from '../achivements/achivements'
// import CooperationStages from '../cooperationStages/cooperationStages'
// import Partners from '../partners/partners'
// import OfferText from '../offer/offer'
// import Contact from '../contact/contact'
// import AboutIntro from '../aboutIntro/aboutIntro'
// import Blog from '../blog/blog'
// import Advantages from '../ourAdvantages/advantages'
// import MobileMenu from '../mobileMenu/mobileMenu'
// import ScrollToTop from '../scroll'

const OutsourcingPage = ({ eacPage, about }) => {
    // const [isMenuOpen, setIsMenuOpen] = useState(false)

    // const myRef = useRef(null)
    // const myRef2 = useRef(null)
    // const myRef3 = useRef(null)

    // function executeScroll(ref) {
    //     window.scroll({
    //         top: ref.current.offsetTop,
    //         behavior: "smooth",
    //     })
    //     setIsMenuOpen(false)
    // }

    return (
        <Container>
            <ContentBlock>
                <div></div>
                <div>
                <NavLink to='/'> <button><span/> Strona główna</button></NavLink> 
                    <SectionTitle>Korzyści outsourcingu</SectionTitle>
                    <Text>
                        <p>Outsourcing procesów to jeden z najszybciej rozwijających się obecnie modeli wsparcia biznesu. Pozwala na przekazanie przez firmę części procesów, zadań czy projektów do realizacji przez wyspecjalizowany podmiot zewnętrzny. Pozwala to zabezpieczyć procesy bez angażowania działu HR, gdyż to outsourcer zajmuje się rekrutacją, zatrudnieniem, planowaniem i kontrolą pracy pracowników.</p>
                        <p>Outsourcing daje możliwość zwiększenia przewagi konkurencyjnej, dzięki stałemu dążeniu firmy outsourcingowej do wzrostu efektywności i jakości obsługiwanych procesów. Pozwala również na ograniczenie ryzyka związanego z działalnością w niepewnym otoczeniu gospodarczym – za budowanie zespołów, ich wyniki i elastyczność struktury zatrudnienia odpowiada dostawca usługi outsourcingowej.</p>
                    </Text>
                </div>
            </ContentBlock>
            <ContentBlock>
                <SectionTitle>Korzyści dla Twojej firmy</SectionTitle>
                <List>
                    <p>Redukcja kosztów</p>
                    <p>Możliwość skupienia się na core-businessie</p>
                    <p>Oszczędność czasu i uwolnienie zasobów własnych</p>
                    <p>Ograniczenie ryzyka</p>
                    <p>Możliwość dokładnego planowania kosztów</p>
                    <p>Dostęp do profesjonalnych, nowoczesnych rozwiązań i najlepszych praktyk</p>
                    <p>Stałe podnoszenie efektywności i jakości procesów</p>
                </List>
            </ContentBlock>
            <ContentBlock>
                <SectionTitle>Outsourcing w produkcji i logistyce</SectionTitle>
                <Text>
                    <p>Wybór odpowiedniego partnera biznesowego do obsługi wybranego procesu jest niezwykle istotny. Outsourcer musi posiadać niezbędne zaplecze eksperckie – specjalistów z zakresu zarządzania liniami produkcyjnymi czy procesami logistycznymi. Powinien mieć także doświadczenie w pozyskiwaniu i utrzymaniu pracowników.</p>
                    <p>Oferowany przez EWL Group outsourcing produkcji i logistyki, to nie tylko zarządzanie, planowanie, harmonogramowanie, szkolenie, To odpowiedzialność za efekt, jego jakość i terminowość.</p>
                </Text>
                <div className='list_title'>Dzięki outsourcingowi usług produkcyjnych i logistycznych:</div>
                <List>
                    <p>Nie musisz martwić się o pracowników</p>
                    <p>Redukujesz koszty związane ze stratami w opakowaniach, odpadem produkcyjnym itp.</p>
                    <p>Podnosisz jakość swoich usług</p>
                    <p>Zwiększasz efektywność procesów</p>
                    <p>Zyskujesz wiedzę o najlepszych praktykach na rynku</p>
                    <p>Płacisz za realne i zgodne ze standardami efekty</p>
                    <p>Precyzyjnie planujesz koszty</p>
                </List>
            </ContentBlock>
            <Contact about={true} />
        </Container>
    )
}

export default OutsourcingPage