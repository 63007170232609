import styled from 'styled-components'
import {color} from '../../constants/theme'
import media from 'styled-media-query'
// import bg from '../../images/bg1.png'

export const Container = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 auto;
    padding: 150px 0 130px;
    
    h2{
        margin-bottom: 30px;
        color: ${color('text')};
    }

    .img{
        width: 47%;
        position: relative;
       
        img{
            width: 100%;
            display: block;
        }

        :after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0.70;
        }

        ${media.lessThan('790px')`
            display: none;
        `}
    }

    ${media.lessThan('1100px')`
        width: 90%;
        padding: 120px 0 100px;
    `}

    ${media.lessThan('768px')`
        width: auto;
        padding: 0 30px 100px;
    `}
`
export const Text = styled.div`
    width: 45%;
    
    span{
        font-weight: 700;
    }

    p:last-child{
        margin-top: 20px;
        color: ${color('accent')};
        font-weight: 700;
        transition: all 0.3s ease;
        cursor: pointer;

        svg{
            position: relative;
            top: 0;
            margin-left: 10px;
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke: ${color('accent')};
            stroke-width: 2;
            transform: translateX(-5px);
            transition: all .3s ease;
        }

        :hover{
            transform: translateX(3px);
        }
    }

    ${media.lessThan('1200px')`
        width: 50%;
    `}

    ${media.lessThan('790px')`
        width: 100%;
    `}
`