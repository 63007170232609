import logo from './logo.svg';
import './App.css';
import Contact from '../src/components/contact/contact'
import Routes from '../src/components/routes/routes.js'

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
