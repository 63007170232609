import React from "react"
import { Container, Text } from "./styles"
import { SectionTitle } from '../titles/index'
import Bg from '../../images/price.webp'

const Price = ({ executeScroll, myRef3, myRef2 }) => {
  return (
    <Container ref={myRef2}>
      <Text>
        <SectionTitle>Cennik + Pakiety dla firm </SectionTitle>
        <p>Dla firm, które zatrudniają lub planują zatrudnić duże grupy cudzoziemców, <span>wprowadziliśmy unikalne systemy abonamentowe</span> obejmujące szereg usług legalizacyjnych. Takie rozwiązanie przynosi oszczędności  oraz dodatkowe korzyści, zarówno dla pracowników jak i dla firm, które ich pozyskują.</p>
        <p onClick={() => executeScroll(myRef3)}>
          Aby dowiedzieć się więcej, skontaktuj się z nami
          <svg width="13px" height="10px" viewBox="0 0 13 10">
            <path d="M1,5 L11,5"></path>
            <polyline points="8 1 12 5 8 9"></polyline>
          </svg>
        </p>
      </Text>
      <div className="img"><img alt='img' src={Bg} /></div>
    </Container>
  )
}

export default Price