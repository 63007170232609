import React from "react"
import { FeaturesContainer } from "./styles"
import Slide from 'react-reveal/Slide'
import group from '../../images/group.png'
import person from '../../images/person2.png'
import verifed from '../../images/verified.png'
import rejected from '../../images/rejected.png'

const Features = ({ myRef2 }) => {
    return (
        <FeaturesContainer ref={myRef2}>
            <Slide left>
                <div className="block">
                    <h6 className="title">Bez platformy</h6>
                    <div className="feature"><p>Ilość pracowników zaangażowanych w projekt w każdym ossziale 2-3 os</p> <img src={group} alt='img' /></div>
                    <div className="feature"><p>Poziom rotacji</p> <span className="number">20-40%</span></div>
                    <div className="feature"><p>Terminowość realizacji zamówienia</p> <span>powyżej 1 m-ca</span></div>
                    <div className="feature"><p>Czas nawiązania współpracy pomiędzy Agencją a Pracodawcą Użytkownikiem</p> <span>2-18 miesięcy</span></div>
                    <div className="feature"><p>Znikoma możliwość weryfikacji kompetencji pracownika - niska jakość delegowanych osób</p> <img src={rejected} alt='img' /></div>
                </div>
            </Slide>
            <Slide right>
                <div className="block">
                    <h6 className="title">Z platformą</h6>
                    <div className="feature"><p>Jednoosobowa obsługa współpracy między firmą (wszystkimi oddziałami) a Agencjami</p> <img src={person} alt='img' /></div>
                    <div className="feature"><p>Poziom rotacji</p> <span className="number">5%</span></div>
                    <div className="feature"><p>Dotrzymanie terminów realizacji</p> <span>około 2 dni</span></div>
                    <div className="feature"><p>Znacznie skrócony czas nawiązania współpracy nawet o 60%</p> <span>krótszy o 60%</span></div>
                    <div className="feature"><p>Badanie kompetencji pracownika-znaczenie wyższa jakość i efektywność delegowanych pracowników</p> <img src={verifed} alt='img' /></div>
                </div>
            </Slide>
        </FeaturesContainer>
    )
}

export default Features