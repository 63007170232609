import styled from 'styled-components'
import bg from '../../images/bg_main1.webp'
import media from 'styled-media-query'
import { color } from '../../constants/theme'

export const Container = styled.div`
    background-image: url(${bg});
    background-color: ${color('text')};
    background-size: contain;
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${color('text')};
    position: relative;
    padding-left: 80px;
    
    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, rgba(28,38,64,1) 40%, rgba(255,255,255,0));
    }

    .content{
        position: relative;
        z-index: 1;

        ${media.lessThan('768px')`
            margin: auto;
        `} 
    }

    .logos{
        position: relative;
        z-index: 1;
        padding-top: 50px;
        display: flex;
        justify-content: space-between;

        .eac_logo{
            width: 70px;
            margin-right: 70px;

            ${media.lessThan('768px')`
                margin-right: 0;
                width: 60px;
            `}
        }

        ${media.lessThan('768px')`
            padding-top: 30px;
        `}
    }

    ${media.lessThan('1100px')`
        background-size: cover;
    `}


    ${media.lessThan('900px')`
        background-position: center;
        
        :after{
            background: ${color('text')};
            opacity: 0.8;
        }
    `}

    ${media.lessThan('768px')`
        padding: 0 30px;
    `} 
`
export const Title = styled.div`
    color: #fff;
    font-size: 62px;
    letter-spacing: 3px;
    margin-bottom: 30px; 
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 60px;

    span{
        color: ${color('accent')};
    }

    ${media.lessThan('768px')`
        font-size: 42px;
        text-align: center;

        br{
            display: none;
        }
    `}

    ${media.lessThan('460px')`
        font-size: 38px;
    `}
`
export const Logo = styled.img`
    width: 20%;

    ${media.lessThan('1370px')`
        width: 25%;
    `}

    ${media.lessThan('1024px')`
        width: 40%;
    `}
    
    ${media.lessThan('768px')`
        width: 280px;
    `}

    ${media.lessThan('500px')`
        width: 180px;
    `}
`
export const Buttons = styled.div`
    z-index: 1;
    display: flex;
    align-items: center;
    margin-bottom: 100px;

    button{
        font-size: 16px;
        letter-spacing: 0.8px;
        padding: 15px 0px;
        background: linear-gradient(90deg, rgb(230, 81, 92) 0%, rgb(240, 136, 94) 42%, rgb(251, 175, 67) 100%);
        display: block;
        color: #fff;    
        width: 170px;
        border-radius: 25px;
        transition: all 0.4s ease;

        :hover{
            transform:  translateY(-0.25em);
            box-shadow: 0 0.5em 0.5em -0.4em  ${color('accent')};
        }
    }
    
    ${media.lessThan('768px')`
        a{
            width: 100%;
        }
        button{
            width: 100%;
        }
    `}

    .leg_btn{
        margin-left: 12px;
        background: transparent;
        background-color: rgba(251, 175, 67, 1);

        ${media.lessThan('768px')`
            margin-left: 0;
            margin-top: 15px;
        `} 
    }

    .out_btn{
        margin-left: 12px;
        background: transparent;
        background-color: rgba(251, 175, 67, 1);
        white-space: nowrap;
        width: 200px;

        ${media.lessThan('768px')`
            margin-left: 0;
            margin-top: 15px;
            width: 100%;
        `} 
    }

    ${media.lessThan('768px')`
        flex-direction: column;
        margin-bottom: 40px;
    `} 
`