import styled from 'styled-components'
import media from 'styled-media-query'
import { color } from '../../constants/theme'

export const Container = styled.div`
    width: 77%;
    margin: 0 auto;
    padding-bottom: 130px;
    position: relative;

    :after{
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        background-color: rgb(255, 251, 239);
        height: 20%;
        z-index: 0;
    }

    h2{
        color: ${color('text')}; 
        margin: 0 0 50px 0;
    }

    ${media.lessThan('1024px')`
        width: 90%;
    `}

    ${media.lessThan('800px')`
        padding-bottom: 90px;
        width: 80%;

        :after{
            display: none;
        }
    `}
`
export const Line = styled.div`
    height: 3px;
    background-color: ${color('text')};
    width: 100%
    margin: 0 auto;

    ${media.lessThan('800px')`
        display: none;
    `}
`
export const Triangles = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
    text-align: center;
    position: relative;
    z-index: -2;

    .triangle {
        width: 0; 
        height: 0; 
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid ${color('accent')};
    }

    span{
        margin: 35px 0 10px;
        display: block;
        font-weight: 800;
    }

    .triangles_item{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    p{
        width: 80%;
    }

    ${media.lessThan('800px')`
        display: none;
    `}
`

export const Mobile = styled.div`
    height: 380px;
    display: none;
    
    .line{
        height: 100%;
        width: 3px;
        background-color: ${color('text')};
    }

    .triangle {
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid ${color('accent')};
        transform: rotate(-90deg);
        margin-right: 15px;
    }

    .triangles_item{
        display: flex;
        align-items: center;
    }

    .text{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    span{
        font-weight: 800;
    }

    ${media.lessThan('800px')`
        display: flex;
    `}
`