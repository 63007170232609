import React, { useState, useEffect } from "react"
import { Container, Content, Title, Line } from "./styles"
import checked from '../../images/checked.png'
import Slide from 'react-reveal/Slide'

const LegalizationIntro = () => {
    return (
        <Slide top>
            <Container>
                <Title>Trafiłeś w dziesiątkę!</Title>
                <Content>
                    <div className="text">
                        <div><img src={checked} alt='icon' />Audyty legalności</div>
                        <div><img src={checked} alt='icon' />100% pozytywnych kontroli PIP i SG</div>
                        <div><img src={checked} alt='icon' />Stała opieka koordynatora</div>
                        <div><img src={checked} alt='icon' />Oferta szyta na miarę</div>
                        <div><img src={checked} alt='icon' />Gwarancja terminowości i skuteczności</div>
                    </div>
                </Content>
            </Container>
        </Slide>
    )
}

export default LegalizationIntro