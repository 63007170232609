import React, { useState, useEffect } from "react"
import { Container } from "./styles"
import img from '../../images/leg.webp'

const CooperationFormsText = () => {
    return (
        <Container>
            <div className="img"><img src={img} alt='img' /></div>
            <p>
                <span className="line" />
                Często wyprzedzamy potrzeby rynku oferując najbardziej dopasowane i efektywne formy współpracy.
                Wiemy, że w biznesie liczy się szybkość i jakość, dlatego jesteśmy na bieżąco z polskim
                prawodawstwem i aktualnymi zmianami przepisów.
            </p>
        </Container>
    )
}

export default CooperationFormsText