import styled from 'styled-components'
import media from 'styled-media-query'
import { color } from '../../constants/theme'
import bg from '../../images/about_intro4.webp'

export const Container = styled.div`
  background-color: ${color('text')};
  height: 100vh;
  background-image: url(${bg});
  background-size: contain;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 75px;

  :after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(28,38,64,1) 40%, rgba(255,255,255,0));
  }

  ${media.lessThan('1200px')`
    background-size: cover;
  `}

  ${media.lessThan('900px')`
    padding: 0 50px;
  `}

  ${media.lessThan('768px')`
    padding: 0 20px;
    :after{
      background: ${color('text')};
      opacity: 0.8;
    }
  `}
`
export const Text = styled.div`
  position: relative;
  z-index: 1;
  color: ${color('white')};
  width: 43%;
  line-height: 1.6;

  h2{
    margin-bottom: 15px;
    font-size: 26px;
    color: ${color('accent')};
    white-space: nowrap;

    ${media.lessThan('1024px')`
      white-space: inherit;
    `}

    ${media.lessThan('768px')`
      font-size: 18px;
    `}
  }

  button{
    position: relative;
    margin-top: 40px;
    padding: 19px 22px;
    transition: all .2s ease;
    display: flex;
    align-items: center;
    color: ${color('white')};

    :before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      border-radius: 28px;
      background: ${color('accent')};
      opacity: 0.9;
      width: 56px;
      height: 56px;
      transition: all 0.3s ease;
    }
    span{
      position: relative;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: .2em;
      vertical-align: middle;
    }
    svg{
      position: relative;
      top: 0;
      margin-left: 10px;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: ${color('white')};
      stroke-width: 2;
      transform: translateX(-5px);
      transition: all .3s ease;
    }
    &:hover{
      &:before{
        width: 100%;
        background: rgba(#000,1);
      }
      svg{
        transform: translateX(0);
      }
    }
    &:active{
      transform: scale(.96);}

      ${media.lessThan('400px')`
        margin-top: 20px;
      `}
    }

    ${media.lessThan('1360px')`
      width: 55%;
    `}

    ${media.lessThan('1100px')`
      width: 70%;
    `}

    ${media.lessThan('900px')`
      width: 100%;
    `}

    ${media.lessThan('400px')`
      font-size: 14px;
    `}
`