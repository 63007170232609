import React from 'react'
import { Container, SubTitle, ServicesItems, ServicesItem, Img, Text } from './styles'
import { SectionTitle } from '../titles'
import support from '../../images/support.png'
import house from '../../images/house.webp'
import rent from '../../images/rent.webp'
import doc from '../../images/doc1.webp'

const AditionalServices = () => {
    return (
        <Container>
            <SectionTitle>Dodatkowe usługi</SectionTitle>
            <SubTitle>Naszym Klientom oferujemy także dodatkowe usługi, do których należą:</SubTitle>
            <ServicesItems>
                <ServicesItem>
                    <Img alt='img' src={house} />
                    <Text>Research rynku mieszkań w oparciu o potrzeby przyszłych pracowników</Text>
                </ServicesItem>
                <ServicesItem>
                    <Img alt='img' src={rent} />
                    <Text>Bezpośredni wynajem mieszkań dla firmy Klienta</Text>
                </ServicesItem>
                <ServicesItem>
                    <Img alt='img' src={doc} />
                    <Text>Pomoc w uzyskaniu PESEL lub meldunek</Text>
                </ServicesItem>
                <ServicesItem>
                    <Img alt='img' src={support} />
                    <Text>Wsparcie techniczne podczas pierwszych tygodni pobytu w Polsce: otwarcie rachunku bankowego, wymiana prawa jazdy itp.</Text>
                </ServicesItem>
            </ServicesItems>
        </Container>
    )
}

export default AditionalServices