export const blogData = [
  {
    name: "Sztuczna intelegencja a rynek pracy tymczasowej",
    date: "07.08.2023",
    id: 34,
    img: "https://i.ibb.co/h9dcWTD/1691399393353.jpg",
    desc: "Rozwój sztucznej inteligencji widoczny jest już niemalże w każdym sektorze gospodarki i w każdej branży, także na rynku pracy...",
    text: [
      <p>Rozwój sztucznej inteligencji widoczny jest już niemalże w każdym sektorze gospodarki i w każdej branży, także na rynku pracy tymczasowej. Tradycyjne procesy rekrutacyjne stają się coraz bardziej czasochłonne i kosztowne, zarówno dla pracodawców, jak i agencji pracy. Uczestnicy tego rynku poszukują bardziej efektywnych rozwiązań, zwłaszcza logistyka, produkcja sezonowa w FMCG oraz przemysł. Na ile zastosowanie AI, narzędzi analitycznych i uczenia maszynowego zmieni rynek pracy tymczasowej? Czego mogą spodziewać się pracodawcy a czego pracownicy?</p>,
      <h6>Automatyzacja procesów</h6>,
      <p>Algorytmy AI analizują duże ilości danych, takie jak umiejętności, doświadczenie, referencje i preferencje, co umożliwia szybkie i precyzyjne dopasowanie odpowiednich kompetencji do wymogów. Dzięki temu firmy osiągają efektywniejsze dopasowanie kandydatów do stanowisk. Szczególnie istotne jest to, kiedy w grę wchodzą rekrutacje masowe z ograniczonym w czasie procesem rekrutacji. Aby jednak AI mogło zadziałać, potrzebuje bazy danych kandydatów.</p>,
      <p>Bez własnej platformy i bazy danych kandydatów, sztuczna inteligencja wydaje się być bezużyteczna. Jeśli natomiast posiadamy własne bazy, na bieżąco aktualizowane, to narzędzie w postaci algorytmów AI daje ogromne możliwości obsługi procesów masowych rekrutacji – mówi Piotr Rafalski, prezes Formiki Agencji Pracy Tymczasowej. – Jestem zwolennikiem implementowania nowoczesnych technologii do świata rynku pracy. Na bazie swoich doświadczeń zbudowałem własne narzędzie – platformę EAC Solution, która nie tylko jest platformą bazy kandydatów, ale też upraszcza procesy związane z masowym zatrudnianiem pracowników tymczasowych obniżając koszty rekrutacji nawet o 60%. To swoisty marketplace pracy tymczasowej w Polsce. Ta innowacyjna platforma jest bazą dostosowaną do zamieniającego się otoczenia technologicznego, a także do czasów poszukiwania oszczędności i optymalizacji. Służy producentom, operatorom logistycznym a także firmom z branży FMCG.</p>,
      <p>Wdrożenie sztucznej inteligencji przyspiesza. Jak wskazuje raport ManpowerGroup Global Insights (IT World of Work 2023) już ponad 50% organizacji twierdzi, że wdrożyło sztuczną inteligencję, co stanowi 2,5-krotny wzrost w porównaniu z 2017 r. To wiąże się też z automatyzacją oraz robotyzacją pracy i procesów. Globalny rynek robotyki wzrośnie z około 25 mld USD w 2021 roku do 160-260 mld USD do 2030 roku, przy czym sprzedaż robotów przemysłowych i logistycznych wyniesie około 80 mld USD. Według raportów Gartnera do 2024 roku organizacje obniżą koszty operacyjne o 30% poprzez połączenie automatyzacji z procesami operacyjnymi. Z kolei  UiPath obliczyło, że automatyzacja procesów robotyki pozwoli firmom zaoszczędzić 55 mld USD rocznie do 2025 r. Oznacza to całkowitą zmianę rynku pracy i procesów rekrutacyjnych.</p>,
      <h6>Elastyczność i dostępność dla kandydata</h6>,
      <p>Dzięki automatycznym systemom zarządzania i planowania, procesy rekrutacyjne a później kontrola czasu pracy i procesu zatrudnienia stają się bardziej efektywne. Z drugiej strony AI oraz inne pokrewne technologie umożliwiają pracownikom tymczasowym dostęp do bardziej różnorodnych ofert pracy.</p>,
      <p>Platformy internetowe łączą pracodawców z dostępnymi kandydatami, umożliwiając im swobodne korzystanie z różnych źródeł pracy i wybór projektów odpowiadających ich umiejętnościom i preferencjom. To zwiększa elastyczność i możliwość pracy na czas określony, co jest atrakcyjne dla wielu osób poszukujących różnorodności i równowagi między życiem zawodowym a prywatnym.</p>,
      <h6>Wyzwania na rynku pracy tymczasowej</h6>,
      <p>Choć AI i nowoczesne technologie wprowadzają wiele korzyści na rynek pracy tymczasowej, warto pamiętać też o pewnych wyzwaniach. Wielu pracowników może obawiać się o utratę pracy z powodu robotyzacji i automatyzacji.</p>,
      <p>Ważne jest, aby wdrażać te zmiany w sposób odpowiedzialny i zapewnić wsparcie dla pracowników przechodzących przez proces transformacji. AI i nowoczesne technologie odgrywają kluczową rolę w zmianach na rynku pracy tymczasowej. Pracodawcy i agencje rekrutacyjne mogą cieszyć się zwiększoną efektywnością, a pracownicy tymczasowi z większych możliwości wyboru i elastyczności. Jednakże zawsze kluczowym elementem będzie odpowiednie zarządzanie tymi zmianami.</p>
    ],
  },
  {
    name: "Pozyskiwanie kandydatów do pracy",
    date: "14.07.2023",
    id: 33,
    img: "https://i.ibb.co/s6j3T9S/33.webp",
    desc: "Pozyskiwanie kandydatów do pracy sezonowej w okresie wakacyjnym to spore wyzwanie, ale nie dla agencji pracy tymczasowej ...",
    text: [
      <p>🌞Pozyskiwanie kandydatów do pracy sezonowej w okresie wakacyjnym to spore wyzwanie, ale nie dla agencji pracy tymczasowej! 💼📆</p>,
      <p>👥Rozumiemy dobrze, jak brak odpowiednich kandydatów może wpływać na efektywność biznesową. Dlatego podejmujemy wszelkie możliwe działania, aby skutecznie pozyskać odpowiednich kandydatów przez cały rok, także w trudnych wakacyjnych okresach.</p>,
      <p>🌞W jaki sposób radzimy sobie z tym wyzwaniem?</p>,
      <p>✅Tworzymy atrakcyjne oferty, które przyciągają uwagę szukających pracy tymczasowej i pokazujemy korzyści ze współpracy z nami.</p>,
      <p>✅Wykorzystujemy zaawansowane technologie (własny system EAC Solution) i dostęp do szerokiej bazy danych, które umożliwiają nam efektywne dotarcie do potencjalnych kandydatów oraz szybkie przeprowadzenie procesu rekrutacji.</p>,
      <p>✅Kładziemy nacisk na budowanie długotrwałych relacji z naszymi kandydatami. Zapewniamy im wsparcie, jasne komunikaty i elastyczność w dopasowaniu pracy do ich preferencji, co umożliwia nam tworzenie społeczności lokalnych pracowników, którzy są chętni do podjęcia tymczasowych zleceń w każdej porze roku.</p>,
      <p>✅Nieustannie doskonalimy nasze metody, aby skutecznie reagować na zmieniające się potrzeby rynku pracy i dostarczać naszym klientom najlepszych kandydatów, niezależnie od sezonu.</p>,
      <p>👉Jeśli poszukujesz pracowników tymczasowych lub chciałbyś dowiedzieć się więcej o tym, jak możemy pomóc Twojej firmie, skontaktuj się z Formika - Agencja pracy tymczasowej. Razem stworzymy rozwiązania, które sprostają Twoim oczekiwaniom!</p>
    ],
  },
  {
    name: "Jak skutecznie i szybko przeprowadzić rekrutację?",
    date: "07.07.2023",
    id: 32,
    img: "https://i.ibb.co/jDyrss2/32.webp",
    desc: "Sezon letni to gorący czas na rynku pracy tymczasowej. W wielu branżach a zwłaszcza w #logistyka #turystyka #handel i ...",
    text: [
      <p>Sezon letni to gorący czas na rynku pracy tymczasowej. W wielu branżach a zwłaszcza w #logistyka #turystyka #handel i #produkcja potrzebne jest szybko dodatkowe wsparcie, czasami z dnia na dzień.</p>,
      <p>🤔Jak podejść do rekrutacji pracowników tymczasowych, kiedy w Waszym regionie rynek kandydata jest mocno ograniczony i trudno jest znaleźć pracowników, zwłaszcza tych wykwalifikowanych.</p>,
      <p>➡️Jeśli szukacie odpowiedzi na to pytanie zapraszam do lektury artykułu, w którym odpowiadam na to pytanie: <a href='https://focusonbusiness.eu/pl/wiadomosci/sezon-pracy-tymczasowej-w-pelni-jak-skutecznie-i-szybko-przeprowadzic-rekrutacje/29179' target='_blank'></a></p>
    ],
  },
  {
    name: "Kim jest dobry lider?",
    date: "27.06.2023",
    id: 31,
    img: "https://i.ibb.co/XkQ1NyC/16878606072.webp",
    desc: "Od lat obserwuję pracodawców i style zarządzania, które prezentują ich liderzy i managerowie. Często niestety złe ...",
    text: [
      <p>Dzisiaj chciałbym podzielić się z Wami refleksjami na temat zarządzania, nie tylko w agencji pracy!</p>,
      <p>Od lat obserwuję pracodawców i style zarządzania, które prezentują ich liderzy i managerowie. Często niestety złe zarządzanie powoduje frustrację zespołów i de facto straty dla firmy. Nie wystarczy założyć garnitur, czy „kamizelkę mocy” aby błyszczeć przed zespołem. To czego oczekują pracownicy od swoich liderów w tych trudnych czasach, to przede wszystkim zdecydowanie i odpowiedzialność za podejmowane decyzję a też komunikatywność i otwartość na pracownika.
      Bo to właśnie zdolność do podejmowania trudnych decyzji, pewność siebie i skupienie na celu tworzą silne przywództwo, które wpływa na całą organizację. Skuteczne przywództwo to jasna wizja i strategia działalności, które pomagają zespołowi skoncentrować się na najważniejszych celach.</p>,
      <p>Dobry lider jest też wzorem dla innych pracowników. Jego zaangażowanie i pozytywne nastawienie wpływają na atmosferę pracy, motywując innych do osiągania lepszych wyników. Dobry szef potrafi też jasno komunikować swoje oczekiwania i cele, a przy tym umiejętnie słuchać innych.</p>,
      <p>Kluczowym elementem w zarządzaniu jest też empatia, która umożliwia liderowi zrozumienie potrzeb i motywacji zespołu, co przekłada się na większe zaangażowanie i efektywność w pracy. Umiejętność zrozumienia i współodczuwania to dzisiaj tak samo pożądana cecha jak wytrwałość w dążeniu do celu. Bo wszystko razem tworzy harmonijne środowiska pracy!</p>,
      <p>I tego wszystkim życzę, harmonii i dobrego przywództwa!</p>
    ],
  },
  {
    name: "Wypadki w pracy",
    date: "19.06.2023",
    id: 30,
    img: "https://i.ibb.co/86hNPkr/exhaustedshop.webp",
    desc: "Sezon pracy tymczasowej i migracji zarobkowych w pełni, niestety jest to również sezon zwiększonej liczby wypadków ...",
    text: [
      <p>💡Sezon pracy tymczasowej i migracji zarobkowych w pełni, niestety jest to również sezon zwiększonej liczby wypadków przy pracy. Pośpiech, brawura, niedbalstwo o narzędzia pracy mogą skończyć się tragicznie.</p>,
      <p>💡Chociaż wskaźnik wypadkowości w pracy maleje z roku na rok, to ciągle odnotowujemy tysiące zdarzeń rocznie. Jak podaje GUS w roku 2022 wypadkom przy pracy uległo ponad  66 tys. osób. To i tak mniej o 3,2% niż w 2021 r. Jednakże ciężkiemu albo śmiertelnemu uszkodzeniu ciała uległo ponad 500 pracowników😞, głównie w rolnictwie, przemyśle, budownictwie oraz w sektorze usług magazynowych.</p>,
      <p>Co zrobić kiedy pracownik tymczasowy ulegnie wypadkowi?🤔</p>,
      <p>👉Wyjaśniam w poniższym video.</p>,
      <a href="https://www.linkedin.com/posts/piotrrafalski_recruitment-pracatymczasowa-agro-activity-7076459184208195584-E-Sy?utm_source=share&utm_medium=member_desktop" target='_blank'>kliknij aby obejrzeć filmik</a>,
    ],
  },
  {
    name: "Kim jest Formika?",
    date: "13.06.2023",
    id: 29,
    img: "https://i.ibb.co/m8Mf4D0/16866520181.jpg",
    desc: "Dobra agencja pracy rozumie ludzi i lubi z nimi pracować. W obsłudze klienta liczy się szybkość reakcji, a jednocześnie ...",
    text: [
      <p>💡Dobra agencja pracy rozumie ludzi i lubi z nimi pracować. W obsłudze klienta liczy się szybkość reakcji, a jednocześnie dokładność i uważność na jego potrzeby. Elastyczność to kolejna cecha, którą cenią sobie pracodawcy we współpracy z agencją, tak samo jak wartości.
      Taka jest właśnie Formika - Agencja pracy tymczasowej❗️</p>,
      <p>✅Jeśli szukasz rzetelnego partnera, który pomoże znaleźć nie tylko odpowiednich pracowników i dobrać odpowiednią umowę współpracy, ale też pomoże ci przełamać bariery związane z zatrudnieniem obcokrajowców i przy tym załatwi wszystkie formalności – zgłoś się do Formika - Agencja pracy tymczasowej</p>,
      <p>✅Co zyskasz współpracując z nami?</p>,
      <p>Przede wszystkim profesjonalne pozyskanie pracowników i dostęp do bazy ponad 1500 kandydatów gotowych do podjęcia współpracy w kraju i zagranicą.
      W związku z tym, że korzystamy z nowoczesnych narzędzi i własnych systemów (EAC Solution), oszczędzisz czas i zasoby w dziale HR na obsługę pracowników tymczasowych.</p>,
      <p>✅Dostosujemy ofertę i zakres działań do Twoich potrzeb. Zrealizujemy zlecenie na terenie kraju i w Europie.</p>,
      <p>📞Po prostu sprawdź jak z nami się współpracuje😊 ⤵️</p>
    ],
  },
  {
    name: "Brak rąk do pracy? Efektywność?",
    date: "06.06.2023",
    id: 28,
    img: "https://i.ibb.co/R2tSHnh/male-engineer-female-factory-employees-hardhats-walking-plant-floor-talking-man-pointing-equipment-i.webp",
    desc: "Brak rąk do pracy? Efektywność? A co z wdrożeniem pracownika do pracy?! To temat, który spędza sen z powiek działom...",
    text: [
      <p>✅Brak rąk do pracy? Efektywność? A co z wdrożeniem pracownika do pracy?! To temat, który spędza sen z powiek działom HR i specjalistom od BHP. Często niestety traktuje się to zagadnienie pobieżnie, zwłaszcza w odniesieniu do pracowników tymczasowych.🤔</p>,
      <p>❗️To błąd! Bo, jak możemy wymagać jakości pracy, kiedy pracownik nie wie dokładnie na czym polegają jego obowiązki pracy?</p>,
      <p>✅Zweryfikujcie swoje procedury wdrożeniowe, czy aby na pewno spełniają wszystkie potrzebne warunki a przy tym są przekazane w przyjazny i zrozumiały sposób?</p>,
      <p></p>,
      <p>❓Czemu to takie ważne, odpowiedź poniżej w moim kolejnym video!</p>,
      <a href="https://www.linkedin.com/posts/piotrrafalski_efektywnoagjafd-hr-bhp-activity-7071783689495416832-3XUq?utm_source=share&utm_medium=member_desktop" target='_blank'>kliknij aby obejrzeć filmik</a>,
      <p></p>,
      <p>A jeśli chciałbyś dowiedzieć się więcej, zapraszamy do kontaktu ⤵️</p>
    ],
  },
  {
    name: "Jak zweryfikować profesjonalizm agencji pracy tymczasowej?",
    date: "23.05.2023",
    id: 27,
    img: "https://i.ibb.co/wNkgpX2/29.webp",
    desc: "Na rynku funkcjonuje ok. 10 tys. agencji pracy, w tym połowa to agencje pracy tymczasowej.🤔 Często borykają się one z...",
    text: [
      <p>Na rynku funkcjonuje ok. 10 tys. agencji pracy, w tym połowa to agencje pracy tymczasowej.🤔 Często borykają się one z negatywnymi opiniami i to z dwóch stron – pracodawców i kandydatów do pracy.</p>,
      <p>❓Jak sprawdzić rzetelność wybranej agencji i jak wybrać tę, której będziemy mogli zaufać?</p>,
      <p>👇W poniższym filmiku podaję kilka kroków, które pomogą nam zweryfikować profesjonalizm agencji pracy tymczasowej.</p>,
      <a href="https://www.linkedin.com/posts/piotrrafalski_na-rynku-funkcjonuje-ok-10-tys-agencji-activity-7066692300839559168-RTpH?utm_source=share&utm_medium=member_desktop" target='_blank'>kliknij aby obejrzeć filmik</a>,
      <p></p>,
      <p>A jeśli chciałbyś dowiedzieć się więcej, zapraszam do kontaktu ⤵️</p>
    ],
  },
  {
    name: "Dobrze przygotowany plan wdrożenia - połowa sukcesu",
    date: "19.05.2023",
    id: 26,
    img: "https://i.ibb.co/QK7FD9Z/28.jpg",
    desc: "Zatrudniając pracownika tymczasowego, chcemy aby pracował, zaznaczę wydajnie, od 1 minuty! Bo przecież płacimy za każdą...",
    text: [
      <p>Zatrudniając pracownika tymczasowego, chcemy aby pracował, zaznaczę wydajnie, od 1 minuty! Bo przecież płacimy za każdą minutę jego pracy.
      🤔Niestety, często wychodzimy z założenia, że skoro ma wykonywać zwykłe prace, wymagające tylko zdrowych rąk i chęci, to nie ma nic prostszego jak dać mu kartę bhp do podpisania i wskazać miejsce pracy. No i nic więcej nie musimy robić. Niestety takie myślenie oraz działanie prowadzą do samych strat, nawet zagrożeń, a przede wszystkim rzutują na jakość pracy.</p>,
      <p>Najgorszym koszmarem każdego pracownika jest „rzucenie go na głęboką wodę”, czyli np. brak przygotowanego stanowiska pracy, brak dostępu do drzwi wejściowych, czy po prostu brak pokazania zakładu pracy, co powoduje zagubienie pracownika w pierwszych dniach. Takich „grzechów” pracodawców można wymieniać wiele. A przecież proste standardy i prosty plan wdrożenia mogą uchronić nas i pracownika od niepotrzebnego zamieszania oraz chaosu organizacyjnego.  </p>,
      <p>Wystarczy wykonać kilka kroków!</p>,
      <p>
        ✅dokładnie przedstaw obowiązki oraz to na czym polega praca na danym stanowisku, <br/>
        ✅poinformuj z jaką odpowiedzialnością wiąże się ta praca, <br/>
        ✅wyjaśnij jak obsługiwać narzędzia przypisane do tego stanowiska, <br/>
        ✅opisz w jakim stanie pracownik powinien pozostawić swoje stanowisko pracy oraz jak ma poruszać się po obiekcie.
      </p>,
      <p>🚩Pamiętajcie też o komunikacji - czyli gdzie i w jaki sposób pracownik może zgłaszać swoje zapytania, wątpliwości!</p>,
      <p>Dobrze przygotowany plan wdrożenia to połowa sukcesu❗️</p>,
      <p>Jeśli potrzebujesz wsparcia w opracowaniu standardów wdrożenia pracownika zapraszamy do kontaktu ⤵️</p>
    ],
  },
  {
    name: "Bezrobocie, rotacja, cicha rezygnacja",
    date: "16.05.2023",
    id: 25,
    img: "https://i.ibb.co/VBjzmKM/construction-worke.webp",
    desc: "Polska oraz Czechy mają jeden z najniższych poziomów bezrobocia w Unii Europejskiej. Na rynku coraz bardziej odczuwalny...",
    text: [
      <p>Polska oraz Czechy mają jeden z najniższych poziomów bezrobocia w Unii Europejskiej. Na rynku coraz bardziej odczuwalny jest brak kandydatów, zwłaszcza w branży budowlanej.</p>,
      <p>Jak podał Monitor Rynku Pracy w ubiegłym roku na zmianę miejsca pracy zdecydowało się tylko 2 z 10 badanych respondentów.</p>,
      <p>Niska rotacja cieszy, jednakże z drugiej strony wiąże się ona z jednym zjawiskiem, które może być bardzo kosztowne. Pracownicy wprawdzie zostają w firmie i nie szukają nowej pracy, ale swoje obowiązki wykonują tylko w podstawowym zakresie i nie angażują się w życie firmy. To quiet quitting, czyli cicha rezygnacja. W skali światowej ten brak zaangażowania pracowników, za raportem Gallupa, przynosi straty na poziomie ok. 7,8 biliona USD, co stanowi 11 proc. globalnego PKB.</p>,
      <p>Ciekawy jestem, czy Wy zetknęliście się z tym zjawiskiem w swoich organizacjach?</p>,
      <p>Problem braku rąk do pracy, niskiej rotacji i zjawisko cichej rezygnacji może dotyczyć także rynku pracy tymczasowej. I moim zdaniem będzie tak samo kosztowny. Dlatego przygotowując programy onboardingu i wdrożeniowe, warto pamiętać o pracownikach sezonowych.
      Ich zaangażowanie i opinie są ważnym elementem budowania marki dobrego pracodawcy. Pracownicy tymczasowi, tak jak stały zespół, są także wizytówką firmy. </p>,
      <p>Jeśli potrzebujecie wsparcia w rekrutacjach masowych albo przygotowaniu onboardingu zapraszam do kontaktu ⤵️</p>
    ],
  },
  {
    name: "Intensywny okres prac sezonowych wystartował!",
    date: "09.05.2023",
    id: 24,
    img: "https://i.ibb.co/3cSPdpC/FORMIKA-to-Agencja-Pracy-Tymczasowej-od-ponad-12-lat-specjalizujemy-si-zar-wno-w-mi-dzynarodowym-jak.webp",
    desc: "W tym roku około 30 proc. firm w Polsce planuje zatrudniać pracowników tymczasowych. Intensywny okres ...",
    text: [
      <p>🚩W tym roku około 30 proc. firm w Polsce planuje zatrudniać pracowników tymczasowych. Intensywny okres prac sezonowych wystartował i rynek potrzebuje wielu rąk do pracy!</p>,
      <p>🚩Część pracowników pochodzi z innych krajów. Chociaż dane Ministerstwa Rodziny i Polityki Społecznej pokazują, że w 2022 roku wydano ponad 2,3 mln pozwoleń na podjęcie pracy przez cudzoziemców w Polsce, to niestety, wciąż borykamy się z długotrwałymi procedurami. Pracodawcy, którzy czekali do ostatniej chwili z zatrudnieniem do pracy tymczasowej będą musieli liczyć się z wydłużonym okresem załatwienia wszystkich formalności.</p>,
      <p>🚩Do tego dochodzą bariery mentalne. Jednym z wyzwań są różnice kulturowe. Często nasza niewiedza na temat innych kultur jest znikoma i w pośpiechu nie widzimy problemów z zarządzaniem różnorodnością w miejscu pracy, co może prowadzić do poważnych konfliktów na linii pracownik - pracodawca. Ważne jest, abyśmy zaczęli bardziej otwarcie myśleć o zatrudnianiu pracowników z innych krajów i kultur.</p>,
      <p>🚩Zatrudnienie obcokrajowców może przynieść wiele korzyści, nie tylko dostęp do wykwalifikowanej siły roboczej, ale też poszerzenie perspektyw i kreatywności, a także zwiększenie konkurencyjności firmy na rynku. Pomoc we wdrożeniu do pracy obcokrajowca niosą profesjonalne agencje pracy, które wspierają swoich klientów w zarządzaniu zespołami różnorodnymi kulturowo.</p>,
      <p>👉Formika - Agencja pracy tymczasowej zawsze gotowa jest to dzielenia się swoim doświadczeniem i wiedzą, jeśli potrzebujesz solidnego i doświadczonego partnera w tym obszarze zapraszamy do kontaktu 😊</p>
    ],
  },
  {
    name: "Wyzwania na rynku pracy tymczasowej w produkcji i logistyce",
    date: "03.05.2023",
    id: 23,
    img: "https://i.ibb.co/5r7Vyjs/1682674098657.webp",
    desc: "Zawsze warto śledzić to, co się dzieje na rynku😊 Dzisiaj polecam Wam mój komentarz o wyzwaniach, przed jakimi ...",
    text: [
      <p>Zawsze warto śledzić to, co się dzieje na rynku😊 Dzisiaj polecam Wam mój komentarz o wyzwaniach, przed jakimi na początku okresu prac sezonowych stanął rynek pracy tymczasowej.</p>,
      <p>W czasie spowolnienia myśli się o cięciu kosztów w zatrudnianiu, a nie o budowaniu zasobów. To moim zdaniem błąd, bo w myśl zasady „chcesz pokoju, bądź gotowy na wojnę”, warto pewne zasoby utrzymywać, aby nie ponosić dodatkowych kosztów ich ponownego budowania.</p>,
      <p>Rynek pracy tymczasowej podlega różnym fluktuacjom, ale ten rok był dla nas wyjątkowy. Czas uśpienia, który zawsze trwał do lutego, wydłużył się do Wielkanocy, a przedsiębiorcy przespali okres przygotowań. Teraz przyszły nowe wyzwania. Jakie?</p>,
      <p>Dowiecie się z artykułu: <a href='https://lnkd.in/gJSUzQTh' target='_blank'>https://lnkd.in/gJSUzQTh</a></p>
    ]
  },
  {
    name: "Traktowanie pracowników",
    date: "27.04.2023",
    id: 22,
    img: "https://i.ibb.co/nRvN3MP/asi-1-1.webp",
    desc: "Z najnowszych badań rynku pracy wynika, że ponad połowa zatrudnionych najbardziej ceni sobie w pracy równe traktowanie...",
    text: [
      <p>➡️Z najnowszych badań rynku pracy wynika, że ponad połowa zatrudnionych najbardziej ceni sobie w pracy (oprócz zarobków) równe traktowanie. Niestety nierówności w firmach nadal istnieją i najczęściej dostrzegane są na tle płci i wieku, tak wskazuje  51. Monitor Rynku Pracy Randstad.</p>,
      <p>🤔Jako agencja pracy tymczasowej obsługująca polski rynek, dorzuciłbym jeszcze kwestie traktowania i podejścia do obcokrajowców. Moim zdaniem rynek pracy, pomimo braku siły roboczej w wielu branżach, jest zamknięty na obcokrajowców i na inne narodowości. Pracodawcy boją się zderzenia z innością, często brak im przygotowania i zrozumienia. A przecież w dzisiejszym świecie to naturalne, że ludzie migrują za pracą. Jeśli mamy dobrze sformułowane standardy pracy, nie powinno być problemu, bo człowiek dostosuje się do wszystkiego. Tymczasem, w wielu miejscach te standardy nie są spójne, brakuje prostych i zrozumiałych ścieżek wprowadzenia nowego pracownika do pracy, przez co wszyscy czują się zagubieni. To rodzi antagonizmy.</p>,
      <p>📝Duże firmy mają tendencję do tworzenia obszernych instrukcji – tak, wiem, powiecie zaraz, że to właśnie są te standardy – tyle, że te obszerne instrukcje wdrożeniowe najczęściej lądują na papierze a nie w praktyce i tutaj koło się zamyka.</p>,
      <p>Rynek pracy się zmienia i to jak pracownicy postrzegają kwestię równego traktowania, odzwierciedla też zmiany na rynku pracy. Cieszy fakt, że jak wskazuje ww. badanie, zdecydowana większość zatrudnionych potwierdza, że w ich firmie wszyscy są̨ traktowani tak samo, bez względu na wyznanie (75 proc.), narodowość (74 proc.), płeć (74 proc.), czy kolor skóry (73 proc.). Jednocześnie wskazuje, iż widać jednak różnicę przy konfrontacji osobistych postaw pracowników z tym, jak oceniają podejście swoich pracodawców - dla 63 proc. zatrudnionych różnorodność jest istotna lub bardzo istotna, ale tylko nieco ponad połowa respondentów (55 proc.) ma poczucie, że ich pracodawca także pozostaje otwarty na promowanie różnorodności.</p>,
      <p>Zostawiam Was z tą refleksją i życzę dobrego dnia!😊</p>
    ]
  },
  {
    name: "Rynek pracy tymczasowej w stagnacji",
    date: "30.03.2023",
    id: 21,
    img: "https://i.ibb.co/qYpdqPQ/workers.webp",
    desc: "Rynek pracy tymczasowej w stagnacji. Cisza przed burzą, czy pierwsze oznaki głębokiego kryzysu? ...",
    text: [
      <p>Rynek pracy tymczasowej w stagnacji. Cisza przed burzą, czy pierwsze oznaki głębokiego kryzysu?</p>,
      <p>Początek każdego roku na rynku rekrutacji to planowanie zatrudnienia i analiza sytuacji w gospodarce, aby płynnie przejść do działań w kolejnych miesiącach. Ten rok jest zupełnie inny. Sytuacja kryzysowa na świecie mocno doświadcza pracodawców a za tym i kandydatów, zwłaszcza w kwestii zatrudnień masowych i sezonowych. Stagnacja trwa już kolejny miesiąc i nie widać poprawy. Nawet sezon przedświąteczny, który generuje większy ruch w logistyce i produkcji FMCG nie spowodował wzrostu.</p>,
      <p>Jakie zmiany przyniosą kolejne miesiące na rynku pracy tymczasowej dla pracodawców w logistyce i w firmach produkcyjnych?</p>,
      <p>W Polsce działa obecnie ok. 9000 agencji pracy, w tym ok. 5450 agencji pracy tymczasowej. Agencje pracy tymczasowej obsłużyły w poprzednim roku około 650–700 tys. pracowników tymczasowych z czego ok. 50% byli to obcokrajowcy - tak wynika z raportów Polskiego Forum HR.</p>,
      <p>Na jedną agencję pracy tymczasowej przypada ok. 120 pracowników. To niedużo i można powiedzieć, że rynek ten jest dość rozdrobniony. Oczywiście to są dane pochodzące z rejestrów agencji zatrudnienia - ile jest czynnych agencji, tego nie wiadomo. Co roku też kilkaset agencji zostaje wykreślonych z rejestrów i kilkaset zostaje wpisanych jako nowe działalności. Od roku 2021 obserwujemy cykliczny spadek i wyhamowanie popytu na pracę tymczasową (ok. 2% do 3% r/r). To oznacza jeszcze mniej pracy dla agencji, ale też mniejszą elastyczność rynku - komentuje Piotr Rafalski, właściciel  Agencji Pracy Tymczasowej.</p>,
      <p>Mniej pracy tymczasowej z jednej strony zmusza pracodawców do lepszego dbania o zasoby pracowników, które już posiadają, a z drugiej powoduje odpływ kandydata z rynku.</p>,
      <p>50% pracowników tymczasowych to obcokrajowcy, którzy nie będą czekać na poprawę sytuacji, nie mając wiedzy kiedy ona nastąpi, a zaczną szukać pracy na innych rynkach. To spowoduje pewną lukę, którą trudno będzie szybko zapełnić w przypadku sezonowego odbicia gospodarki w górę a za tym również pójdzie wzrost kosztów obsługi rekrutacji.</p>,
      <p>Planowanie zatrudnienia jest trudnym zadaniem. Wahania na rynku podaży i popytu powodują nieprzewidywalność, a przy tak dużych kosztach jakie generuje dziś praca jest to być albo nie być dla przedsiębiorstwa. Nie dziwi mnie ostrożność z jaką firmy podchodzą do zwiększania zatrudnienia, a z drugiej strony reakcja pracowników sezonowych, których celem jest znalezienie źródła zarobku. W przypadku, gdy to źródło wysycha szukają innego – mówi Piotr Rafalski. - Sposobem na to mogą być nowoczesne narzędzia, jak chociażby systemy do planowania i obsługi rekrutacji masowych – np. EAC Solution platforma Formiki APT, które dają większą elastyczność i możliwość szybkiej reakcji na wahania, a przede wszystkim dostępność do zasobów kandydatów i kilkukrotne obniżenie kosztów obsługi rekrutacji masowych - dodaje.</p>,
      <h6>Wiosenne światełko w tunelu</h6>,
      <p>Najnowsze raporty dotyczące rynku pracy wskazują (wg „Barometru ManpowerGroup Perspektyw Zatrudnienia”), że ok. 29% firm działających w sektorze przemysłu i surowców zamierza zwiększać zatrudnienie, ok. 23% zmniejszać, zaś około 50% firm nie zamierza zmieniać jego poziomu. Skupiając się na podaży i popycie, sytuacja ta nie napawa optymizmem, gdyż co prawda wzrost równoważy spadek, to jednak nie jest to odbicie jakiego oczekiwałby rynek agencji pracy i też rynek kandydata. Mimo to, należy optymistycznie przyjąć ten wzrost, jako światełko w tunelu na II połowę roku.</p>,
      <p>Sytuacja w dużej mierze zależeć będzie od inwestycji w przemyśle oraz wahań popytu, a więc wskaźników inflacyjnych. Wielu agencji pracy tymczasowej może nie wytrzymać tej okresowej stagnacji, a ci którzy przetrwają, mając zasoby finansowe, będą beneficjentami każdego kolejnego odbicia w gospodarce. Nie należy doszukiwać się też głębokiego kryzysu, a raczej warto skupić się na rozwoju tak swoich zasobów, jak i potencjału narzędziowego. Liczyć się będą dopasowanie do wyzwań globalnych i lokalnych, jak chociażby włączenie się w zrównoważony rozwój a także optymalizacja kosztów, wszędzie tam gdzie to możliwe. Można to osiągnąć korzystając z digitalizacji i najnowocześniejszych rozwiązań, pomimo iż wydają się one czasochłonne i kosztochłonne w ich wdrażaniu – podsumowuje Piotr Rafalski.</p>,
      <p>👉 Publikacja ukazała się m.in. na portalu <a href='https://www.dlahandlu.pl/detal-hurt/trendy-konsumenckie/rynek-pracy-tymczasowej-w-stagnacji-cisza-przed-burza-czy-pierwsze-oznaki-glebokiego-kryzysu,117330.html' target='_blank'>DlaHandlu.pl</a>. </p>
    ],
  },
  {
    name: "Zapraszam na webinar - Jak obniżyć koszty rekrutacji?",
    date: "27.03.2023",
    id: 20,
    img: "https://i.ibb.co/gSbyC3t/1679910142655.jpg",
    desc: "Zapraszam na webinar - Jak obniżyć koszty rekrutacji? 29 marca 2023, online, godz. 14.00 – 15.30! ...",
    text: [
      <p>💥Zapraszam na webinar - Jak obniżyć koszty rekrutacji? <br/>29 marca 2023, online, godz. 14.00 – 15.30!</p>,
      <p>💥W trakcie webinarium opowiem o tym jak działa market place rekrutacji pracowników tymczasowych i sezonowych, który:<br/>👉 obniża kilkukrotnie koszty i oszczędza czas pracowników HR, <br/>
      👉 umożliwia dostęp do nowoczesnego rozwiązania wpisującego się w zrównoważony rozwój,<br/>
      👉 nie wymaga inwestycji dziesiątek tysięcy złotych we wdrożenia systemów IT.</p>,
      <p>🚀 Po tym webinarium Twoje podejście do rekrutacji masowych zmieni się całkowicie!</p>,
      <p>Webinarium organizują: Formika Agencja Pracy Tymczasowej oraz portal <a href="https://www.pracujwlogistyce.pl/" target='_blank'>Pracujwlogistyce.pl</a>.</p>,
      <p>💥Udział w webinarze jest bezpłatny, aby otrzymać link do spotkania wystarczy potwierdzić swój udział rejestrując się tutaj: <a href="https://lnkd.in/dinQF7gh" target='_blank'>https://lnkd.in/dinQF7gh</a></p>
    ],
  },
  {
    name: "Relaksacją w biurze, magazynie, na produkcji?",
    date: "22.03.2023",
    id: 19,
    img: "https://i.ibb.co/6gfmNdC/1679479747047.jpg",
    desc: "Wczoraj rozpoczęła się kalendarzowa wiosna i chociaż aura nas nie rozpieszcza w tym roku, to już sama myśl o ...",
    text: [
      <p>💥Wczoraj rozpoczęła się kalendarzowa wiosna i chociaż aura nas nie rozpieszcza w tym roku, to już sama myśl o ciepłych dniach powoduje, że zaczynamy tęsknić za urlopem. A do lata jeszcze kilka miesięcy. Mobilizacja zespołu w tym czasie zaczyna być wyzwaniem, bo jak tu myśleć o pracy, kiedy za oknem kwitną kasztany, ptaki śpiewają a słońce działa jak magnes. <br/>
      Ehh…a tu biurko pełne papierów i rozpoczętych spraw, które pilnie czekają na realizację.</p>,
      <p>🔎Obserwując moich pracowników widzę, jak przejście na wiosenny tryb działa na ich percepcję. W branży rekrutacji sezonowych, szybkość reakcji i bycie w ciągłym pogotowiu są wpisane w nasz zawód. Zatrudnienia masowe wymagają błyskawicznych odpowiedzi na zapytania i problemy klientów, jak też szybkich reakcji na dynamicznie zmieniającą się sytuację na rynku kandydatów. Trzeba przyznać, że wyzwań w tym roku nie brakuje.</p>,
      <p>🤔Zastanawiałem się, jak wesprzeć mój zespół i co może mu pomóc w chwilach kiedy dopada nas zmęczenie, albo po prostu przyjdzie chęć oderwania się na moment. Poszedłem za promieniem słońca😉 Umieściłem w biurze hamaki, które nie tylko kojarzą się z ciepłem i plażą, ale można też na nich uciąć sobie chwilową drzemkę.</p>,
      <p>🤩Pierwsza reakcja na hamaki była bezcenna. Już sam ich widok sprawił, że zobaczyłem ponownie błysk w oczach moich pracowników, bo i jakoś w biurze zrobiło się sympatyczniej.😊
      Decyzja o umieszczeniu hamaków okazała się strzałem w dziesiątkę. Sam też z tej opcji chętnie korzystam, co widać na załączonym obrazku😉</p>,
      <p>🤔Ciekawy jestem jak inni radzą sobie z relaksacją w biurze, magazynie, na produkcji?</p>
    ],
  },
  {
    name: "Jak zmniejszyć koszty masowych rekrutacji? – zapraszam na WEBINAR!",
    date: "20.03.2023",
    id: 18,
    img: "https://i.ibb.co/ZgCcpFG/eac1.webp",
    desc: "Jak zmniejszyć koszty masowych rekrutacji? – zapraszam na WEBINAR! 👉29 marca 2023, online, godz. 14.00 – 15.30! ...",
    text: [
      <p>🔑Jak zmniejszyć koszty masowych rekrutacji? – zapraszam na WEBINAR! 👉29 marca 2023, online, godz. 14.00 – 15.30!</p>,
      <p>#logistyka, #przemysł i #produkcja dóbr konsumpcyjnych - branże korzystające najczęściej z pracowników rotujących sezonowo, ponoszą największe koszty z tym związane - a nie muszą, gdyż są już rozwiązania, które mogą obniżyć koszty masowych rekrutacji, nawet kilkukrotnie!</p>,
      <p>
      🔑W trakcie webinarium dowiesz się: <br/>
      🔴ile możesz zaoszczędzić na procesach rekrutacji – pieniędzy i czasu?<br/>
      🔴jakie rozwiązania są dzisiaj dostępne na rynku rekrutacji?<br/>
      🔴jak skorzystać z najnowocześniejszych narzędzi nie inwestując dziesiątek tysięcy złotych?
      </p>,
      <p>👉Po tym webinarium Twoje podejście do rekrutacji masowych zmieni się całkowicie⁉️</p>,
      <p>💡Webinarium organizują: <a href="https://formika-praca.pl/" target='_blank'>Formika - Agencja pracy</a> oraz portal <a href="https://www.pracujwlogistyce.pl/" target='_blank'>Pracujwlogistyce.pl</a></p>,
      <p>💡Webinar poprowadzi: Piotr Rafalski, właściciel i twórca platformy EAC Solution oraz Formiki Agencji Pracy Tymczasowej.</p>,
      <p>Udział w webinarze jest bezpłatny, wystarczy potwierdzić swój udział rejestrując się pod tym linkiem: <a href="https://lnkd.in/dinQF7gh" target='_blank'>https://lnkd.in/dinQF7gh</a></p>
    ],
  },
  {
    name: "Dobry szef powinien ufać swoim pracownikom!",
    date: "15.03.2023",
    id: 17,
    img: "https://i.ibb.co/7gQB46w/2323542534-1-1.webp",
    desc: "Dobry szef powinien ufać swoim pracownikom! Dobry szef powinien być otwarty na dyskusje i feedback! Ludzie nie chcę już silnych i charyzmatycznych szefów, ale wyrozumiałych! ...",
    text: [
      <p>🚩Dobry szef powinien ufać swoim pracownikom!<br/>🚩Dobry szef powinien być otwarty na dyskusje i feedback!<br/>🚩Ludzie nie chcę już silnych i charyzmatycznych szefów, ale wyrozumiałych!</p>,
      <p>💡Rynek pracy i oczekiwania pracowników zmieniają się tak dynamicznie, że wielu organizacjom trudno jest za nimi nadążyć, a czasem nawet trudno im je dostrzec. Dzisiaj pracownicy cenią najbardziej u swoich liderów / pracodawców nie charyzmę czy autorytet, ale otwartość i komunikatywność. Ludzie wolą też płaską strukturę organizacyjną w miejsce rozbudowanej hierarchii. To nie tylko spostrzeżenia, ale twarde dane📊 z najnowszego raportu Skandynawsko-Polskiej Izby Gospodarczej (SPCC) „Miejsce pracy na nowe czasy. Jak skandynawscy pracodawcy motywują i inspirują ludzi” z lutego 2023, który zainspirował mnie do dyskusji.</p>,
      <p>😏Będąc aktywnym uczestnikiem i jednocześnie obserwatorem rynku pracy potwierdzam te zmiany. Szczególnie w kontekście wchodzenia na ten rynek młodszych pokoleń, dla których #wellbeing i równość są jednymi z najwyższych wartości w miejscu pracy. I wiem też, że wielu pracodawców o tym nie myśli, kierując się stereotypami i szkołą zarządzania, którą znają z dawnych czasów. To błąd, bo świat się zmienia i chcemy czy nie, musimy się do tych zmian dostosować.</p>,
      <p>💡W ferworze codziennej gonitwy za wynikami i targetami, nie mamy czasu na mały rekonesans, aż nagle zdajemy sobie sprawę, że stare mechanizmy nie działają, że pomimo wysiłków, które wkładamy w budowanie zespołów nie przynosi to efektów, a żądania pracowników rosną i nie wiemy dlaczego, przecież mają premię, system benefitów, itp.</p>,
      <p>💡Wraz z nadejściem wiosny warto się nad tym zastanowić i zatrzymać na chwilę, spojrzeć na swoje organizacje z perspektywy zmian na rynku - czy na pewno jesteśmy tu gdzie powinniśmy być❓</p>
    ],
  },
  {
    name: "Zarządzanie rekrutacjami masowymi",
    date: "04.03.2023",
    id: 16,
    img: "https://i.ibb.co/HdWnqBc/eac4.webp",
    desc: "Zarządzanie rekrutacjami masowymi, to nie tylko sprawne przeprowadzenie procesu samej rekrutacji, ale też świadomość wyzwań związanych z różnorodnością, tą kulturową i pokoleniową ...",
    text: [
      <p>Zarządzanie rekrutacjami masowymi, to nie tylko sprawne przeprowadzenie procesu samej rekrutacji, ale też świadomość wyzwań związanych z różnorodnością, tą kulturową i pokoleniową.</p>,
      <p>Wracam do tego tematu, bo jest ważny, a w dobie masowych migracji, rynek pracy musi nauczyć się jak zarządzać różnorodnymi zespołami. Wraz z nadejściem wiosny ruchy migracyjne się nasilą i należy spodziewać się wielu rąk poszukujących zatrudnienia. Formika Agencja Pracy Tymczasowej jest gotowa na zwiększone zapotrzebowanie tak na pracę, jak i kandydatów. Staramy się upraszczać procesy rekrutacyjne, oferując nowoczesne rozwiązania systemowe (EAC Solution), które pozwalają minimalizować nakład pracy na sprawy administracyjne, a bardziej skupić się na pracowniku.</p>,
      <p>Przygotowując się do sezonu wiosennego w rekrutacjach masowych pod uwagę bierzemy przede wszystkim dobro pracownika. Rozumiemy, w jakim położeniu znajdują się ludzie, którzy przyjeżdżają do nas z innych krajów. Zawsze wspieramy pracodawców w komunikacji i we wdrażaniu tych osób do pracy.</p>,
      <p>Tutaj mam taki osobisty apel do pracodawców, zwróćmy szczególną uwagę na różnice kulturowe, nie bójmy się ich, nie ignorujmy, ale starajmy się je zrozumieć na tyle ile możemy. Jeśli nie macie takiej możliwości ani środków, albo brakuje wam zwyczajnie czasu, zwróćcie się do agencji, która was wspiera w rekrutacjach. A jeśli ta agencja nie ma potencjału, zapraszam do kontaktu z Formiką Agencją Pracy Tymczasowej. Nasz zespół jest odpowiednio przygotowany do profesjonalnej pomocy w zakresie prowadzenia różnorodnych zespołów.</p>,
      <p>I na koniec, kilka danych dotyczących pracowników z Ukrainy. Otóż według szacunków Narodowego Banku Ukrainy (NBU) już 4,7 mln Ukraińców szuka pracy w swoim kraju lub poza nim. Bezrobocie wynosi tam ok. 33 proc. Bądźmy zatem otwarci na tych ludzi, którzy przyjadą do nas za pracą, nie tylko dając im zatrudnienie, ale starając się zrozumieć ich trudne położenie a także różnorodność, którą ze sobą przyniosą.</p>,
      <p>Jeśli szukasz pomocy w masowych rekrutacjach zapraszam do kontaktu ⤵️</p>
    ],
  },
  {
    name: "Rynek pracy odnotowuje rekordową aktywność zawodową Polaków",
    date: "01.03.2023",
    id: 15,
    img: "https://i.ibb.co/R2tSHnh/male-engineer-female-factory-employees-hardhats-walking-plant-floor-talking-man-pointing-equipment-i.webp",
    desc: "Bijemy rekordy aktywności zawodowej w naszym kraju. Na 1000 pracujących przypada 786 niepracujących. Coraz mniejsza liczba ludności na razie nie robi krzywdy gospodarce, bo jednocześnie ...",
    text: [
      <p>Bijemy rekordy aktywności zawodowej w naszym kraju. Na 1000 pracujących przypada 786 niepracujących. Coraz mniejsza liczba ludności na razie nie robi krzywdy gospodarce, bo jednocześnie rośnie nasza aktywność zawodowa i to rośnie do poziomów rekordowych. – przeczytałam o tym dzisiaj na <a href="https://businessinsider.com.pl/" target='_blank'>Businessinsider.com.pl</a></p>,
      <p>To bardzo dobra wiadomość, zwłaszcza w dobie widma kryzysu, ale też dla nas jako agencji pracy, z punktu widzenia utrzymania odpowiedniego stanu pracowników.</p>,
      <p>Polecam dzisiejszy artykuł: <a href="https://businessinsider.com.pl/gospodarka/bijemy-w-polsce-rekordy-aktywnosci-zawodowej-na-1000-pracujacych-przypada-786/nm8kbnh" target='_blank'>https://lnkd.in/dYpZgrng</a></p>,
    ],
  },
  {
    name: "Bezrobocie w Polsce 2023 rok",
    date: "26.02.2023",
    id: 14,
    img: "https://i.ibb.co/fGt3DmJ/8.webp",
    desc: "Nigdy w historii rynku pracy bezrobocie w Polsce na początku roku nie było tak niskie jak w 2023. Wynosiło w styczniu 5,5 proc. To najnowsze szacunki MRiPS, które też podało, że stopa ta była tylko wyższa o 0,3 pkt ...",
    text: [
      <p>Nigdy w historii rynku pracy bezrobocie w Polsce na początku roku nie było tak niskie jak w 2023. Wynosiło w styczniu 5,5 proc. To najnowsze szacunki MRiPS, które też podało, że stopa ta była tylko wyższa o 0,3 pkt. proc. niż w grudniu 2022 r., co też może wynikać z zakończenia prac sezonowych związanych z okresem przedświątecznym. Z drugiej strony mamy raporty dużych agencji pracy, wskazujących na stabilny wzrost liczby przedsiębiorstw, które zamierzają zwalniać i odwrócenie trendu z rynku kandydata na rynek pracodawcy.</p>,
      <h6>Gdzie leży prawda i co się dzieje na rynku pracy?</h6>,
      <p>Patrząc na dane MRiPS, jako agencja pracy tymczasowej, potwierdzamy sytuację z początku roku (to jest powtarzalne). Styczeń i luty na rynku prac sezonowych to sezon ogórkowy. Lekkie wyhamowanie po intensywnym okresie jesienno -zimowym jest typowe dla tej części rynku pracy i pory roku. Jednakże ta sytuacja jest też lustrem tego, co dzieje się w gospodarce i w otoczeniu geopolitycznym.</p>,
      <p>Póki co mamy niesłabnącą inflację, a za tym też presję na wzrost wynagrodzeń. Właśnie to będzie największym bieżącym problemem pracodawców, którzy i tak borykają się z ogromnym wzrostem kosztów prowadzenia działalności.</p>,
      <h6>Co w takiej sytuacji?</h6>,
      <p>Warto poświęcić nieco więcej czasu na analizę danych i tego co dzieje się w naszym najbliższym otoczeniu. Jak reaguje rynek lokalny na zmiany w skali makro, jak zmienia się potencjał dostępności pracowników, jak możemy zabezpieczyć środki i zasoby na trudny czas. Dzięki temu szybciej znajdziemy drogę do optymalizacji kosztów, a przede wszystkim będziemy w stanie, choć nie w pełni, przygotować się na ewentualne turbulencje, które mogą nadejść wraz z wiosną.</p>,
      <p>Ja stawiam, jak zawsze, na nowoczesne rozwiązania i digitalizację. Eliminacja błędów i ograniczenie papierologii, a przy tym oszczędność czasu, to niezawodny i dość szybki sposób na usprawnienie niektórych procesów w firmie.</p>
    ],
  },
  {
    name: "Nie ma odwrotu od digitalizacji procesów rekrutacyjnych w logistyce i produkcji",
    date: "23.02.2023",
    id: 13,
    img: "https://i.ibb.co/FY0nzT2/digitalizacja-optymalizacja.webp",
    desc: "Na koniec tygodnia polecam dzisiejszy artykuł pt. „Nie ma odwrotu od digitalizacji procesów rekrutacyjnych w logistyce i produkcji”, w którym mówimy o optymalizacji kosztów masowych rekrutacji ...",
    text: [
      <p>Na koniec tygodnia polecam dzisiejszy artykuł pt. „Nie ma odwrotu od digitalizacji procesów rekrutacyjnych w logistyce i produkcji”, w którym mówimy o optymalizacji kosztów masowych rekrutacji przy zatrudnianiu pracowników tymczasowych w logistyce i przemyśle.</p>,
      <p>Digitalizacja wkracza już w każdą dziedzinę naszego życia zawodowego, szczególnie tam gdzie można zaoszczędzić czas, nasze zaangażowanie, a także ograniczyć do minimum biurokrację. Tak, też dzieje się w procesach rekrutacji masowych, które prowadzone tradycyjnymi metodami pochłaniają zbyt wiele czasu i energii.</p>,
      <p>Dzisiaj istnieją możliwości, które pozwalają zrobić to inaczej, szybciej i sprawniej, w sposób bardziej zrównoważony, przy wykorzystaniu systemów informatycznych. A jak tego dokonać, przeczytacie o tym w naszym artykule na portalu Forum Firm: <a href="https://forumfirm.eu/nie-ma-odwrotu-od-digitalizacji-procesow-rekrutacyjnych-w-logistyce-i-produkcji/" target='_blank'>https://lnkd.in/dPb4bjtS</a></p>,
    ],
  },
  {
    name: "Pracownicy też reprezentują Waszą firmę!",
    date: "14.02.2023",
    id: 12,
    img: "https://i.ibb.co/QbqmCNh/two-colleagues-factory.webp",
    desc: "Przy okazji Walentynek, święta miłości, zachęcam abyśmy w tym dniu zwrócili uwagę na uczucia, albo też odczucia wszystkich tych, którzy są daleko od swoich bliskich ...",
    text: [
      <p>Przy okazji Walentynek, święta miłości, zachęcam abyśmy w tym dniu zwrócili uwagę na uczucia, albo też odczucia wszystkich tych, którzy są daleko od swoich bliskich.</p>,
      <p>Mam tu na myśli pracowników tymczasowych, pochodzących z innych krajów, którzy przyjechali do nas „za chlebem” zostawiając swoich ukochanych gdzieś daleko, z nadzieją na lepszą przyszłość.</p>,
      <p>Drodzy pracodawcy pamiętajcie, bez względu na narodowość i kulturę, wiek a także płeć, na pierwszym miejscu zawsze powinien być widziany człowiek, który ma swoją wrażliwość i godność. Starajcie się budować relacje ze wszystkimi pracownikami! Oni też reprezentują Waszą firmę!</p>,
      <p>Pamiętajcie, ci ludzie są w zupełnie obcym dla nich miejscu, przyjechali zarobić pieniądze, ale chcą pracować godnie i w atmosferze szacunku. Nie lekceważcie ich w kwestii komunikacji, odpowiadajcie na ich pytania i wątpliwości, wspierajcie kiedy tego potrzebują, okażcie trochę serca.</p>,
      <p>Równe, zróżnicowane i integracyjne miejsca pracy są kluczowym czynnikiem generującym większą produktywność, innowacyjność i dobrobyt nas wszystkich.</p>
    ],
  },
  {
    name: "Stabilizacja na rynku pracy czy krok do większej zmiany i przechylenia szali z rynku pracownika na rynek pracodawcy?",
    date: "14.02.2023",
    id: 11,
    img: "https://i.ibb.co/BBvpTK6/business-job-interview-concept-1-1.webp",
    desc: "Jak można wyczytać z najnowszych raportów o prognozach dla rynku pracy, liczba firm w usługach konsumpcyjnych, planujących redukcje etatów wynosi obecnie ok. 27% ...",
    text: [
      <p>Jak można wyczytać z najnowszych raportów o prognozach dla rynku pracy, liczba firm w usługach konsumpcyjnych, planujących redukcje etatów wynosi obecnie ok. 27%, a jednocześnie tyle samo firm deklaruje, że planują zatrudniać nowych pracowników.</p>,
      <p>Pozostała część pracodawców nie zamierza wykonywać żadnych większych ruchów w obszarze zatrudnienia. Czy to oznacza stabilizację na rynku pracy, czy też krok do jakiejś większej zmiany i przechylenia szali z rynku pracownika na rynek pracodawcy?</p>,
      <p>Z pewnością ta sytuacja nieco wpływa na wyrównanie tych sił. To jak stąpanie po linie, albo zjazd ze stromego stoku. Do końca ubiegłego roku mieliśmy rynek pracownika. Dzisiaj to się zmienia, coraz więcej kompetencji i rąk do pracy jest dostępnych w różnych sektorach gospodarki. Decyzje o tworzeniu nowych miejsc pracy są podejmowane bardzo ostrożnie i z dużą rezerwą. Firmy poszukują przede wszystkim oszczędności i optymalizacji.</p>,
      <p>Wydawać się może, że dla agencji pracy tymczasowych zaczyna się trudny czas. W jakimś zakresie pewnie tak, ale te agencje które działają uczciwie i potrafią dostosować się do dynamicznych zmian, a przy tym są innowacyjne, mogą na tym zyskać, tak biznesowo jak i rozwojowo. Oczywiście nie wiemy co zadzieje się w gospodarce i na ile globalny kryzys oraz wojna na Ukrainie dotkną nas w tym roku, ale ja mimo wszystko jestem optymistą😊</p>,
    ],
  },
  {
    name: "Wpływ rozwóju technologii ba rynek pracy",
    date: "10.02.2023",
    id: 10,
    img: "https://i.ibb.co/LdR6bDD/photo-automobile-production-line-welding-car-body-modern-car-assembly-plant-auto-industry-interior-h.webp",
    desc: "Ponad 80% ogółu pracowników tymczasowych zatrudnia się w logistyce, na produkcji i w handlu, gdzie też wdraża się najwięcej innowacji ...",
    text: [
      <p>Ponad 80% ogółu pracowników tymczasowych zatrudnia się w logistyce, na produkcji i w handlu, gdzie też wdraża się najwięcej innowacji.</p>,
      <p>Niektórzy twierdzą, że automatyzacja i robotyzacja, które wkraczają już masowo szczególnie w magazynach i na liniach produkcyjnych, ograniczą dostęp do miejsc pracy tymczasowej i generalnie miejsca pracy.</p>,
      <p>Z moich obserwacji wynika, że pewnych procesów nie da się zastąpić maszyną. Również niektóre maszyny-roboty muszą być nadzorowane albo wspierane przez człowieka.</p>,
      <p>Natomiast, rozwój technologii z pewnością umożliwia ograniczenie ciężkiej i powtarzalnej pracy, co czyni wykonywanie niektórych czynności mniej obciążającymi zdrowie. </p>,
      <p>Jako firma młoda i prowadzona przez młody zespół, cieszymy się z postępu technologicznego i digitalizacji. Sami, jak już o tym wspominałem w moich wcześniejszych wpisach, stworzyliśmy własną platformę – cyfrowy market place (EAC Solution) optymalizujący współpracę pomiędzy agencją pracy tymczasowej a jej klientami oraz ograniczający do minimum czas obsługi procesów rekrutacyjnych.</p>,
      <p>Jednakże, tak jak do wszystkich zmian, tak i do tych technologicznych trzeba dojrzeć, bo od postępu nie ma ucieczki.</p>,
      <p>Jeśli szukasz innowacyjnego i solidnego partnera w rekrutacjach masowych, zapraszam do kontaktu ⤵️</p>
    ],
  },
  {
    name: "Jak wygląda obecna sytuacja na rynku pracy?",
    date: "07.02.2023",
    id: 9,
    img: "https://i.ibb.co/NFbpX4w/grafik-1.webp",
    desc: "W Polsce częściej niż w innych krajach pracownicy szukają dodatkowego zatrudnienia, aby sprostać rosnącym kosztom życia: w naszym kraju robi tak ...",
    text: [
      <p>W Polsce częściej niż w innych krajach pracownicy szukają dodatkowego zatrudnienia, aby sprostać rosnącym kosztom życia: w naszym kraju robi tak co trzecia osoba, globalnie: co czwarta.</p>,
      <p>🎯Dobrym rozwiązaniem jest praca tymczasowa, która umożliwia elastyczne godziny pracy.</p>,
      <p>W obliczu trudnej sytuacji ekonomicznej, Polacy szukają przede wszystkim bezpieczeństwa i stabilności zatrudnienia (94 proc.). Jednocześnie zwracają też uwagę na szerszy wachlarz czynników: tj. elastyczny czas pracy – 80 proc. Dla 77 proc. Polaków istotne są również wartości, którymi kieruje się organizacja oraz poczucie sensu wykonywanej pracy.</p>,
      <p>Pawie 1/5 Polaków (19 proc.) planuje później przejść na emeryturę, aby poradzić sobie z trudną sytuacją ekonomiczną. To wyniki najnowszego badania Randstad Workmonitor.</p>,
      <p>🎯W tej sytuacji istotną rolę będą odgrywać agencje pracy tymczasowej, które mogą oferować dodatkowe możliwości zarobkowe, wprowadzając jednocześnie na rynekpracy nowe kompetencje, których poszukują pracodawcy.</p>,
      <p>Jeśli szukacie solidnego partnera na rynku pracy tymczasowej, zapraszam do kontaktu z Formika Agencja Pracy Tymczasowej ⤵️</p>
    ],
  },
  {
    name: "Eac solution a rekrutacja pracowników dla twojej firmy",
    date: "02.02.2023",
    id: 8,
    img: "https://i.ibb.co/cQnwgqb/eac3.webp",
    desc: "Koniec stycznia to czas przygotowań do pierwszych w roku pików sezonowych. Po okresie świątecznej przerwy i ferii zimowych, rynek rozpędza się i ...",
    text: [
      <p>Koniec stycznia to czas przygotowań do pierwszych w roku pików sezonowych. Po okresie świątecznej przerwy i ferii zimowych, rynek rozpędza się i produkcja wchodzi na pełne obroty.</p>,
      <p>To również czas intensywnych przygotowań do masowych rekrutacji, aby zaspokoić potrzeby firm, które swoje zespoły wzmacniają pracownikami tymczasowymi.</p>,
      <p>Zespół Formiki jest gotowy na nowy sezon i nowe wyzwania, a szczególnie rekrutacje z użyciem naszej autorskiej platformy EAC Solution.</p>,
      <p>👉 Ruszamy już z pierwszymi rekrutacjami, jak zawsze spodziewamy się skokowych wzrostów, ale też i spadków.</p>,
      <p>🎯W tym roku postawiliśmy sobie za cel maksymalną optymalizację całego procesu zatrudnienia pracowników tymczasowych i obsługi kontraktów z tym związanych.</p>,
      <p>👉 Przede wszystkim dążymy do zmniejszenia rotacji pracowników, skrócenia czasu potrzebnego na nawiązanie współpracy oraz skrócenia procesów rekrutacji.</p>,
      <p>Jeśli szukasz solidnego partnera do swoich rekrutacji, zapraszam do kontaktu ⤵️</p>
    ],
  },
  {
    name: "Zespół Formika Agencja Pracy Tymczasowej!",
    date: "28.01.2023",
    id: 7,
    img: "https://i.ibb.co/2drXrc6/48-501-544-290-1-1.webp",
    desc: "Znacie już naszą działalność i wdrożenia, ale nie wiecie kto tworzy zespół Formika Agencja Pracy Tymczasowej?! ...",
    text: [
      <p>Znacie już naszą działalność i wdrożenia, ale nie wiecie kto tworzy zespół Formika Agencja Pracy Tymczasowej?!</p>,
      <p>Poznajcie zespół świetnych specjalistów, zgranych i niesamowicie zaangażowanych w swoją pracę. To dzięki nim rośniemy i pomagamy naszym klientom w: <br/>
        - rekrutacji,<br/>
        - legalizacji pobytu,<br/>
        - obsługi projektów związanych z zatrudnieniem tymczasowym.
      </p>,
      <p>Oprócz pracowników biurowych na większości zakładów zatrudniamy koordynatorów terenowych, którzy są na pierwszej linii kontaktu z kontrahentami i naszymi pracownikami.</p>,
      <p>Rekrutacja - kierownikiem działu jest <a href="https://www.linkedin.com/in/konstantyn-monchenko-a68819110/" target="_blank">Konstantyn Monchenko</a>, który jest też pierwszym pracownikiem Formika APT. Kostek jest odpowiedzialny również za dział legalizacji pobytu i analizuje różne obszary w procesie rekrutacji i zatrudnienia. W dziale pracuje jeszcze kilka osób, które odpowiadają bezpośrednio za rekrutacje.</p>,
      <p>Zespołem legalizacji pobytu zarządza <a href="https://www.linkedin.com/in/piotr-st%C4%99pie%C5%84-b92529217/" target='_blank'>Piotr Stępień</a>, który czuwa nad prawidłową dokumentacją pozwalającą na legalny pobyt i pracę naszych pracowników. Dba o to, aby wszyscy pracowali zgodnie z obowiązującym prawem.</p>,
      <p>Za obsługę projektów i pracę project menedżerów odpowiada <a href="https://www.linkedin.com/in/kateryna-danylchuk-480753204/" target='_blank'>Kateryna Danylchuk</a>. Przeprowadza każdego nowo zatrudnionego pracownika przez wszystkie procedury zatrudnienia. Dodatkowo organizuje mu transport na miejsce zamieszkania oraz przekazuje pod opiekę koordynatora terenowego. Project manager odpowiedzialny jest za organizacje całej logistyki tzn. zorganizowanie zamieszkania dla pracowników, transportu do pracy, daty wprowadzenia na zakład, zakup niezbędnej odzieży roboczej oraz kontrolę nad poprawnym wykonywaniem obowiązków przez koordynatorów terenowych. Jest odpowiedzialny za tworzenie relacji z klientem i bieżący kontakt z osobami odpowiedzialnymi za projekt u kontrahentów. Buduje również relacje z pracownikami, które mają wpływ na pozytywny odbiór naszej firmy, co przekłada się na niską rotację oraz lepszą wydajność w pracy.</p>,
      <p>Jestem dumny z mojego zespołu, bo praca na rynku pracy tymczasowej jest dynamiczna i wymaga dużego zaangażowania od każdego z nas.</p>,
      <p>Jeśli chcecie dowiedzieć się jak działa zespół Formika APT w praktyce zapraszam do kontaktu ⤵️</p>
    ],
  },
  {
    name: "Zarządzania różnorodnością na rynku pracy",
    date: "24.01.2023",
    id: 6,
    img: "https://i.ibb.co/dJC4zbW/male-asian.webp",
    desc: "W naszym kraju pracuje legalnie ponad milion cudzoziemców, największą grupą są Ukraińcy, i kolejno Białorusini, Gruzini, Hindusi, Uzbecy ...",
    text: [
      <p>W naszym kraju pracuje legalnie ponad milion cudzoziemców, największą grupą są Ukraińcy, i kolejno Białorusini, Gruzini, Hindusi, Uzbecy, Filipińczycy, Nepalczycy, Bengalczycy…</p>,
      <p>To pokazuje, że nasz rynek pracy staje się tyglem kulturowym, a jednym z największych wyzwań dla pracodawców jest zarządzanie różnorodnością, zwłaszcza gdy chodzi o pracowników tymczasowych. Z pewnością nie jest to łatwe zadanie, trzeba pamiętać nie tylko o różnicach kulturowych czy wyznania, ale też innym rytmie życia, świąt czy choćby sposobach żywienia. </p>,
      <p>Klienci coraz częściej pytają nas, na co trzeba zwrócić uwagę. Odpowiedzi jest wiele, ale dla mnie najważniejsza jest komunikacja i szacunek. To właśnie o tym mówimy o w artykule, który przygotowaliśmy. Zachęcam do jego lektury.</p>,
      <p>Ciekaw też jestem Waszych doświadczeń, czy też zetknęliście się już z tym wyzwaniem? </p>,
      <p><a href='https://managerplus.pl/wzrost-globalnego-bezrobocia-generuje-wieksza-migracje-zarobkowa-co-wymaga-przygotowania-sie-do-zarzadzania-roznorodnoscia-98026' target='_blank'>https://lnkd.in/du8PgYJr</a></p>
    ],
  },
  {
    name: "EAC Solution",
    date: "21.01.2023",
    id: 5,
    img: "https://i.ibb.co/GkMR1qW/eac2-1.webp",
    desc: "Po miesiącach intensywnych prac zakończyliśmy 3 fazę wdrożenia naszej platformy EAC Solution. Co to oznacza w praktyce? Całkowitą zmianę ...",
    text: [
      <p>Po miesiącach intensywnych prac zakończyliśmy 3 fazę wdrożenia naszej platformy EAC Solution. Co to oznacza w praktyce? Całkowitą zmianę modelu nawiązywania współpracy pomiędzy agencjami a pracodawcami.</p>,
      <p>🔑Mamy teraz prawdziwy marketplace pracy tymczasowej. Przed nami kolejna faza wdrożenia – wprowadzenie usługi premium, czyli outsourcingu całościowego procesu związanego z zaspokojeniem potrzeb kadrowych firmy.</p>,
      <p>Jesteśmy dumni, bo EAC Solution to nasze autorskie rozwiązanie i pierwsza na rynku platforma, która ma ułatwić skomplikowane i zajmujące dużo czasu masowe rekrutacje.
        O tym, że nie jest to proste, a przede wszystkim czasochłonne najlepiej wiedzą ci, którzy korzystają z usług agencji zatrudniania retail, produkcja, logistyka.</p>,
      <h6>Co w EAC Solution jest najważniejsze?</h6>,
      <p>👉Standaryzacja procesów – jeden system dla firm i agencji</p>,
      <p>👉Optymalizacja kosztów i czasu – mniej czasu przeznaczonego na obsługę projektów rekrutacyjnych, więcej czasu dla rozpoczęcia kolejnych</p>,
      <p>👉Automatyzacja procesów – czyli szybsza, prostsza i bardziej przejrzysta komunikacja</p>,
      <p>Jeśli chcecie dowiedzieć się więcej i lepiej poznać naszą platformę EAC Solution, zapraszam do kontaktu ⤵️</p>
    ],
  },
  {
    name: "Prognozy dla rynku pracy",
    date: "15.01.2023",
    id: 4,
    img: "https://i.ibb.co/WWZKdSp/jobmarket.webp",
    desc: "W tym tygodniu pojawiło się kilka opracowań raportowych na temat prognoz dla rynku pracy, z których wynika, że czeka nas stagnacja i widać ...",
    text: [
      <p>💡W tym tygodniu pojawiło się kilka opracowań raportowych na temat prognoz dla rynku pracy, z których wynika, że czeka nas stagnacja i widać coraz wyższą tendencję pracodawców do redukcji etatów.</p>,
      <p>Oczywiście nie da się ukryć, że ledwie po wyjściu z zawirowań w okresie pandemii, teraz nastąpiło wyhamowanie, bo cała gospodarka UE mocno przysiadła, a do tego dochodzą inne wydarzenia i turbulencje na świecie, które destabilizują biznes a za tym i rynek pracy.</p>,
      <p>Jednak nie taki diabeł straszny jako go malują i firmy nie podejmują pochopnych decyzji o redukcji zatrudnienia, a raczej przygotowują się do zwrotu sytuacji utrzymując zasoby, które pozwolą im szybko wrócić na tory w momencie przyspieszenia.</p>,
      <p>Nadal naszej gospodarce potrzeba wiele rąk do pracy zwłaszcza na poziomie blue collars. Rozwój logistyki czy przemysłu, jaki ostatnio obserwujemy, ma swoje podłoże w lokowaniu inwestycji w Polsce i obsłudze rynków europejskich z naszego kraju, co się nie zmieniło.</p>,
      <p>Z pewnością zmienia się struktura kosztów i planowania. Tutaj pod uwagę należy brać zarówno czynniki makroekonomiczne jak chociażby inflacja, jak i te mikro w skali lokalnej. Lokalne mogą być najbardziej dotkliwe dla poszczególnych grup zawodowych przy masowych zwolnieniach.</p>,
      <p>💡Nie spodziewałbym się jednak szybkiego przejścia z rynku kandydata do rynku pracodawcy!</p>,
      <p>Na rynku agencji pracy tymczasowej właśnie zaczyna się ruch i na razie widzimy ograniczenia kosztowe, ale nie popytowe. Potrzeby nie zmalały drastycznie, chociaż obaw nie brakuje.</p>,
      <p>Formika trzyma rękę na pulsie i stara się pomóc swoim klientom wykorzystując cały swój potencjał i doświadczenie, także w optymalizacji procesów zatrudnienia, a za tym i ograniczania kosztów. W tych czasach ważna jest elastyczność i szybka reakcja, a przede wszystkim dobra komunikacja.</p>,
      <p>Jeśli jesteś ciekawy jak działamy albo potrzebujesz wsparcia w zakresie rekrutacji, zapraszam do kontaktu ⤵️</p>
    ],
  },
  {
    name: "Rekrutacja w trzech klikach dzięki EAC Solution",
    date: "5.01.2023",
    id: 3,
    img: "https://i.ibb.co/ZgCcpFG/eac1.webp",
    desc: "Początek roku to planowanie, a szczególnie w obszarze zatrudniania pracowników. Dzisiaj każdy szuka optymalizacji i oszczędności, a procesy ...",
    text: [
      <p>Początek roku to planowanie, a szczególnie w obszarze zatrudniania pracowników. Dzisiaj każdy szuka optymalizacji i oszczędności, a procesy rekrutacji są czasochłonne i wymagają zaangażowania całych zespołów.💰</p>,
      <p>Naszym zdaniem nie musi tak być, gdyż wystarczy jedna osoba aby skutecznie zarządzać wielkimi rekrutacjami!</p>,
      <p>Firmom zatrudniającym masowo pracowników tymczasowych (#produkcja, #logistics, #supplychain, #reatil, sieci handlowe i dystrybucyjne) proponujemy:
      👉 zmniejszenie rotacji w firmie do 5%
      👉 skrócenie czasu potrzebnego na nawiązanie współpracy z agencją pracy o 60%
      👉 skrócenie całego procesu rekrutacji i jego obsługi aż o 90%</p>,
      <p>Wszystko to można osiągnąć korzystając z platformy EAC Solution – stworzonej przez Agencję Pracy Tymczasowej Formika Piotr Rafalski.</p>,
      <p>Chcesz dowiedzieć się więcej, skontaktuj się z nami, a pokażemy Ci jak to działa! ⤵️</p>,
    ],
  },
  {
    name: "Jak usprawnić masowe rekrutacje pracowników?",
    date: "23.12.2022",
    id: 2,
    img: "https://i.ibb.co/31xj03M/optymalizacja-1.jpg",
    desc: "Każdy, kto rekrutuje masowo pracowników na produkcję, do magazynów, czy w branży dystrybucji i handlu, doskonale zdaje sobie sprawę, że obsługa procesów ...",
    text: [
      <p>🔑Każdy, kto rekrutuje masowo pracowników na produkcję, do magazynów, czy w branży dystrybucji i handlu, doskonale zdaje sobie sprawę, że obsługa procesów z tym związanych jest jednym z najbardziej czasochłonnych i zbiurokratyzowanych obszarów działalności.</p>,
      <p>Powszechnie wiadomo, że procesy w rekrutacji masowej, począwszy od ofertowania, przeglądu i ocen reputacji, ciągną się tygodniami. Paraliżuje to elastyczność, której tak bardzo wszyscy dzisiaj oczekują (także nasi pracownicy). A wcale tak nie musi być!</p>,
      <p>🔑Działając w tej branży kilka lat, często zderzałem się ze ścianą biurokracji i czasu. Zastanawiałem się jak to zmienić, jak pewne procesy uprościć. Zacząłem od prostych obliczeń, ile czasu nam pochłania obsługa takich rekrutacji. I wiecie co wyszło? Zliczając wszystkie procesy, pochłania nam to nawet 5 dni roboczych – tyle wymaga obsługa jednego niewielkiego kontraktu zatrudnienia do pracy tymczasowej. Ale ten czas można skrócić o 90%.</p>,
      <p>🔑Jeśli jesteście ciekawi jak to zrobić, zapraszam do artykułu: <a href="https://www.pracujwlogistyce.pl/11-tydzien-1/8779-jak-usprawnic-masowe-rekrutacje-pracownikow-powstaje-nowy-marketplace" target='_blank'>https://lnkd.in/dRfVC25Q</a>, w którym opisano moje autorskie rozwiązanie, Formika Piotr Rafalski - EAC Solution, a po więcej szczegółów do kontaktu ⤵️</p>,
    ],
  },
  {
    name: "Formika - podsumowanie rozwoju dziłalności",
    date: "20.12.2022",
    id: 1,
    img: "https://i.ibb.co/5jnNbHJ/1670486930241.jpg",
    desc: "Kiedy w grudniu pierwsze prezenty trafiają do adresatów, jak to dzieci mówią od małego Mikołaja😊 czas jakby zwalnia na chwilę. Rynek pracy tymczasowej łapie ...",
    text: [
      <p>Kiedy w grudniu pierwsze prezenty trafiają do adresatów, jak to dzieci mówią od małego Mikołaja😊 czas jakby zwalnia na chwilę. Rynek pracy tymczasowej łapie oddech po wytężonym sezonie przedświątecznych przygotowań na produkcji, w magazynach i dystrybucji.</p>,
      <p>Co prawda, dla mikołajów i kurierów rozpoczęły się żniwa, które potrwają do Wigilii, ale generalnie agencje pracy wchodzą w czas kilkutygodniowej hibernacji.</p>,
      <p>Przyznaję, że lubię ten stan, bo mogę odpiąć guzik w marynarce, odetchnąć na luzie i spokojnie pomyśleć… o świętach też, ale głównie o tym, co zdarzyło się w tym roku i jak wystartować w nowym.</p>,
      <p>Mija kolejny rok działalności Formiki agencji pracy, którą założyłem 5 lat temu i która ma już na swoim koncie zatrudnienie kilkuset pracowników, obsługę kilkunastu korporacji, a nawet nowatorskie rozwiązania. 5 lat, to nie jubileusz, a jednak okrągła data, która jest cennym doświadczeniem.</p>,
      <p>Po tym okresie działalności wiem jedno, chcę dalej rozwijać Formikę i działać prężnie na rynku pracy tymczasowej, wdrażać innowacyjne projekty oraz zdobywać zaufanie większej rzeszy klientów.</p>,
      <p>Jeśli szukacie wsparcia w rekrutacjach masowych, zapraszam do kontaktu ⤵️</p>
    ],
  },
];