import React, { useRef } from "react"
import { Container, ContactInfo, ContactForm, TopBtn } from './styles'
import { ReactComponent as Logo } from '../../images/mail.svg'
import { SectionTitle } from '../titles/index'
import { Button } from '../buttons/buttons'
import Footer from '../footer/footer'
// import { Mailer } from 'nodemailer-react'

const Contact = ({ myRef3, about, eacPage }) => {

    let address = about ? 'mailto:pr@formika-praca.pl' : 'mailto:p.stepien@formika-praca.pl'
    let addressSend = about ? '9b3f674e-8587-4165-94bb-027b7e311326' : 'a92698da-c00f-4d00-bd1f-37ec06c50378'

    return (
        <>
            <Container ref={myRef3}>
                <TopBtn onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                    <span className='arrow' />
                </TopBtn>
                <SectionTitle>Zapraszamy do kontaktu</SectionTitle>
                <div className='flex'>
                    <ContactForm action="https://api.web3forms.com/submit" method="POST">
                        <input type="hidden" name="access_key" value={addressSend} />
                        <input placeholder='Imię i nazwisko' type="text" name="Imię" required />
                        <input placeholder='E-mail' type="email" name="Email" required />
                        <textarea placeholder='Wiadomość' rows="6" type="text" name="Wiadomość" required />
                        <input type="hidden" name="redirect" value="https://web3forms.com/success"></input>
                        <Button type='submit'>Wyślij</Button>
                    </ContactForm>
                    <ContactInfo>
                        <div>
                            NIP 118 171 53 34 <br />
                            REGON 362315588 <br />
                            KRAZ 14554
                        </div>
                        <div>
                            <span>Biuro:</span>
                            <a href='https://goo.gl/maps/Wt6oagEXjhDtVPMq5' target='_blank'>
                                ul. Warszawska 55 <br />
                                05-092 Łomianki
                            </a>
                        </div>
                        <div>
                            <a href="tel:+48501544290"> +48 501 544 290 </a>
                            <span className="name_kontakt">Piotr Rafalski - Właściciel</span>
                            {
                                !eacPage ? <a href="tel:+48730088898"> +48 730 088 898 </a> : <span></span>
                            }
                            {
                                !eacPage ? <span className="name_kontakt">Piotr Stępień - Kierownik Ds. Legalizacji Pobytu</span> : <span></span>
                            }
                            {/* <a href="tel:+48730088898"> +48 730 088 898 </a> */}
                            {/* <span className="name_kontakt">Piotr Stępień - Kierownik Ds. Legalizacji Pobytu</span> */}
                        </div>
                        <div>
                            <a href={address}><Logo />{about ? 'pr@formika-praca.pl' : 'p.stepien@formika-praca.pl'}</a>
                        </div>
                    </ContactInfo>
                </div>
            </Container>
            <Footer />
        </>
    )
}

export const WelcomeEmail = ({ firstName }) => ({
    subject: `👋 ${firstName}`,
    body: (
        <div>
            <p>Hello {firstName}!</p>
            <p>Hope you'll enjoy the package!</p>
        </div>
    )
})

export default Contact