import styled from 'styled-components'
import media from 'styled-media-query'
import { color } from '../../constants/theme'
import bg from '../../images/achiv_bg.png'

export const Container = styled.div`
    padding: 80px 0;
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    :after{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: ${color('text')};
      opacity: 0.7;
    }

    .container_inner{
        display: flex;
        justify-content: space-between;
        width: 75%;
        margin: 0 auto;
        position: relative;
        z-index: 1;

        ${media.lessThan('1375px')`
            width: 85%;
        `}

        ${media.lessThan('1200px')`
            width: 90%;
        `}

        ${media.lessThan('1024px')`
            width: 100%;
        `}

        ${media.lessThan('768px')`
            flex-direction: column;
        `}
    } 
    
    ${media.lessThan('1024px')`
        padding: 80px 30px;
    `}
`
export const AchivementsItem = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 251, 239, 0.9);
    color: ${color('text')};
    margin-right: 45px;
    padding: 40px 0;

    :last-child{
        margin-right: 0;
    }

    ${media.lessThan('1200px')`
       margin-right: 25px;
       padding: 30px 5px;
    `}

    ${media.lessThan('768px')`
       margin-right: 0;
       margin-bottom: 25px;

       :last-child{
            margin-bottom: 0;
        }
    `}
`
export const Number = styled.div`
    color: ${color('accent')};
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 700;
    margin: 20px 0 7px;
`
export const Text = styled.div`
    font-weight: 800;
    font-size: 14px;
    text-align: center;
`
export const Img = styled.img`
    width: 60px;
`