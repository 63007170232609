import React, { useState, useEffect } from "react"
import { Container, Text } from "./styles"
import Slide from 'react-reveal/Slide'

const AboutIntro = ({ executeScroll, myRef }) => {
    return (
        <Slide top>
            <Container>
                <Text>
                    <h2>FORMIKA - Agencja Pracy Tymczasowej</h2>
                    {/* <p>od ponad 12 lat specjalizujemy się zarówno w międzynarodowym, jak i krajowym pośrednictwie pracy. Zajmujemy się profesjonalnym doradztwem zawodowym, pośrednictwem i leasingiem pracowniczym. W przeciągu kilku lat zbudowaliśmy prężnie działający dział legalizacji pobytu. Nasz zespół składa się ze specjalistów, którzy przez lata zdobywali doświadczenie w tym zakresie. Zapraszamy do współpracy wszystkich, których interesuje całkowicie legalne zatrudnienie obcokrajowca, pomagamy załatwić wszelkie formalności związane z pobytem w naszym kraju.</p> */}
                    <p>Dobrze rozumiemy ludzi i lubimy z nimi pracować. Wiemy, że w biznesie liczy się szybkość i jakość, dlatego szybko testujemy nowości, wybieramy najlepsze z nich i wdrażamy je w celu poprawy wyników. Często wyprzedzamy potrzeby rynku oferując najbardziej dopasowane i efektywne formy współpracy przy zatrudnieniu pracowników: leasing, outsourcing oraz unikalne systemy abonamentowe. Budując biznes i rozwijając firmę ważne są także wartości. Kluczowe dla nas jest przełamywanie bariery myślenia o pracownikach zza granicy tylko jako o taniej sile roboczej. Najbardziej cieszy nas to, że jesteśmy w stanie stworzyć dla ludzi warunki, dzięki którym mogą żyć na dobrym poziomie, wspierać swoje rodziny i rozwijać się zawodowo.</p>
                    <button onClick={() => executeScroll(myRef)}>
                        <span>Więcej</span>
                        <svg width="13px" height="10px" viewBox="0 0 13 10">
                            <path d="M1,5 L11,5"></path>
                            <polyline points="8 1 12 5 8 9"></polyline>
                        </svg>
                    </button>
                </Text>
            </Container>
        </Slide>
    )
}

export default AboutIntro