import styled from 'styled-components'
import media from 'styled-media-query'
import {color} from '../../constants/theme'
import bg from '../../images/leg_intro_bg.webp'

export const Container = styled.div`
    background-color: ${color('text')};
    height: 100vh;
    background-image: url(${bg});
    background-size: contain;
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    position: relative;
    padding-left: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // background: #1C2640;
        background: linear-gradient(90deg, rgba(28,38,64,1) 40%, rgba(255,255,255,0));
        // opacity: 0.75;
    }

    ${media.lessThan('1200px')`
        background-size: cover;
    `}

    ${media.lessThan('768px')`
        padding: 0 30px;
        
        :after{
            background: ${color('text')};
            opacity: 0.8;
         }
    `}
`
export const Content = styled.div`
    position: relative;
    z-index: 1;
    color: ${color('white')};
    margin-top: 50px;
    margin-bottom: 70px;

    .text{
        font-size: 20px;

        div{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        img{
            width: 22px;
            height: 100%;
            margin-right: 12px;
        }
    }

    ${media.lessThan('1200px')`
        margin-bottom: 0;
    `}

    ${media.lessThan('768px')`
        width: fit-content;
        margin: 50px auto 0;
    `}
`
export const Title = styled.div`
    font-size: 52px;
    color: ${color('white')};
    font-weight: 800;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    
    ${media.lessThan('768px')`
        font-size: 38px;
        white-space: inherit;
        text-align: center;
    `}
`
export const Line = styled.div`
    display: flex;

    span{
        display: block;
        width: 30px;
        height: 30px;
        background: ${color('accent')};
        position: relative;
        border-radius: 50%;

        :after{
            content: '';
            position: absolute;
            width: 150px;
            height: 1.5px;
            background: ${color('accent')};
            top: 190%;
            transform: rotate(35deg);
        }
    }
`