import styled from 'styled-components'
import media from 'styled-media-query'
import {color} from '../../constants/theme'

export const Button = styled.button`
    font-family: "Garet-Book", sans-serif;
    font-size: 14px;
    letter-spacing: 0.8px;
    padding: 12px 40px;
    background: ${color('accent')};
    display: block;
    color: ${color('text')};    
    width: fit-content;
    border-radius: 25px;
    font-weight: 700; 
`
// export const ArrowBtn = styled.button`
//     border-radius: 50%;
//     border: 2px solid ${color('accent')};
//     width: 55px;
//     height: 55px;
//     position: relative;
//     z-index: 1;
//     margin-top: 30px;

//     :after {
//         content: " ";
//         display: block;
//         opacity: 0;
//         width: 59px;
//         height: 59px;
//         position: absolute;
//         right: -5px;
//         top: -5px;
//         border-radius: 50%;
//         border: 1px solid #fff;
//         border-color: #fff transparent #fff transparent;

//         @keyframes animation {
//             0% {transform: rotate(0deg);}
//             100% {transform: rotate(360deg);}
//           }
//     }

//     :hover{
//         :after{
//             opacity: 1;
//             animation: animation 1.2s linear infinite;
//         }
//     }

//     .arrow {
//         position: absolute;
//         top: 50%;
//         right: 13px;
//         width: 50%;
//         height: 2px;
//         background-color: ${color('accent')};;
//         box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
//         transform: rotate(90deg);
//         border-radius: 5px;
//     }
      
//     .arrow::after, .arrow::before {
//         content: '';
//         position: absolute;
//         width: 65%;
//         height: 2px;
//         right: -3px;
//         background-color: ${color('accent')};
//         border-radius: 5px;
//     }
      
//     .arrow::after {
//         top: -5px;
//         transform: rotate(40deg);
//     }
      
//     .arrow::before {
//         top: 5px;
//         box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
//         transform: rotate(-40deg);
//     }
// `
export const BurgerBtn = styled.div`
    display: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 95px;
    transform: translateY(-50%);
    z-index: 100;

    .bar1, .bar2, .bar3 {
        width: 25px;
        height: 2px;
        background-color: #FFF8F2;
        margin: 6px 0;
        transition: 0.4s;
    }

    .bar1{
        transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(-45deg) translate(-5px, 6px)' : 'rotate(0) translate(0, 0)')};
    }

    .bar2{
        opacity: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '1')};
    }

    .bar3{
        transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(45deg) translate(-5px, -6px)' : 'rotate(0) translate(0, 0)')};
    }

    ${media.lessThan('768px')`
        display: block; 
    `}
`