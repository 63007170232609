import styled from 'styled-components'
import {color} from '../../constants/theme'
import media from 'styled-media-query'

export const Container = styled.div`
    color: ${color('text')};
    text-align: center;

    h2{
        color: ${color('text')};
        margin: 0 auto 20px;
    }
`
export const SubTitle = styled.div`
    margin-bottom: 50px;

    ${media.lessThan('1250px')`
        margin: 0 30px 50px;
    `}
`
export const ServicesItems = styled.div`
    display: flex;
    width: 80%;
    margin: 120px auto 0;

    ${media.lessThan('1250px')`
        width: 90%;
    `}

    ${media.lessThan('1024px')`
        width: auto;
        margin: 100px 30px 0;
    `}

    ${media.lessThan('930px')`
        flex-direction: column;
        align-items: center;
    `}
`
export const ServicesItem = styled.div`
    background: ${color('white')};
    margin-right: 25px;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 85px 25px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    :last-child{
        margin-right: 0;

        ${media.lessThan('930px')`
            img{
                top: -45%;
            }
        `}

        ${media.lessThan('500px')`
            img{
                top: -35%;
            }
        `}
    }

    ${media.lessThan('930px')`
        margin: 0 auto 90px !important;
        width: 75%;
        max-height: 200px;
    `}
`
export const Img = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 15px solid #fff;
    position: absolute;
    top: -30%;

    ${media.lessThan('1000px')`
        top: -25%;
    `}

    ${media.lessThan('930px')`
        top: -50%;
    `}
`
export const Text = styled.div`
    font-size: 14px;
`