import styled from 'styled-components'
import {color} from '../../constants/theme'
import media from 'styled-media-query'

export const Container = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 auto;
    padding: 120px 0 90px;
    
    h2{
        margin-bottom: 30px;
        color: ${color('text')};
    }

    .img{
        width: 45%;
        position: relative;
       
        img{
            width: 100%;
            display: block;
        }

        :after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.2);
            opacity: 0.70;
        }

        ${media.lessThan('768px')`
            display: none;
        `}
    }

    .text{
        width: 48%;
    
        span{
            font-weight: 700;
        }

        .line{
            width: 90px;
            height: 4px;
            background: ${color('accent')};
            margin-bottom: 20px;
            display: block;

            ${media.lessThan('768px')`
                margin: 0 auto 25px;
            `}
        }

        ${media.lessThan('1200px')`
            width: 50%;
        `}

        ${media.lessThan('768px')`
            width: 100%;
            text-align: center;
        `}
    }

    ${media.lessThan('1100px')`
        width: 90%;
        padding: 100px 0 20px;
    `}

    ${media.lessThan('768px')`
        width: auto;
        padding: 100px 20px 20px;
    `}
`
export const Text = styled.div`
    width: 48%;
    
    span{
        font-weight: 700;
    }

    .line{
        width: 90px;
        height: 4px;
        background: ${color('accent')};
        margin-bottom: 20px;
        display: block;

        ${media.lessThan('768px')`
            margin: 0 auto 25px;
        `}
    }

    ${media.lessThan('1200px')`
        width: 50%;
    `}

    ${media.lessThan('768px')`
        width: 100%;
        text-align: center;
    `}
`