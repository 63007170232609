import styled from 'styled-components'
import { color } from '../../constants/theme'
import media from 'styled-media-query'
import bg from '../../images/contact4.webp'

export const Container = styled.section`
    padding: 90px 170px 80px 150px;
    // background: ${color('main')};
    font-family: "Garet-Book", sans-serif;
    color: #fff;
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    position: relative;

    .flex{
        display: flex;
        justify-content: space-between;
    }

    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #1C2640;
        opacity: 0.8;
    }

    h2{
        margin-bottom: 35px;
    }

    ${media.lessThan('1200px')`
    padding: 90px 100px 80px 100px;
    `}

    ${media.lessThan('1024px')`
        padding: 100px 30px 50px 30px;
    `}

    ${media.lessThan('768px')`
        .flex{
            flex-direction: column;
        } 
        h2{
            margin-bottom: 20px;
        }
    `}
`
export const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 40%;
    position: relative;
    margin-top: 30px;
    z-index: 1;

    input, textarea{
        font-family: "Garet-Book", sans-serif;
        font-size: 14px;
        color: ${color('white')};
        background: transparent;
        border-bottom: 2px solid ${color('accent')};
        margin-bottom: 25px;
        padding-bottom: 8px;
        border-radius: 0;
    }

    input::placeholder, textarea::placeholder{
        font-family: "Garet-Book", sans-serif;
        font-size: 14px;
        color: ${color('white')};
    }

    button{
        width: 100%;
        margin-top: auto;
    }

    ${media.lessThan('768px')`
        width: 100%;
    `}
`
export const ContactInfo = styled.div`
    font-size: 16px;
    position: relative;
    z-index:1;

    div{
        margin-top: 30px;
        line-height: 24px;
        
        span{
            color: ${color('accent')};
            margin-bottom: 5px;
            display: block;
        }

        .name_kontakt{
            display: block;
            color: ${color('white')};
            font-size: 14px;
            margin-bottom: 20px;
        }

        :nth-child(3){
            color: ${color('accent')};
            line-height: 22px;
        }
    }

    a{
        display: flex;
        align-items: center;

        svg{
            width: 20px;
            margin-right: 7px;
            height: 100%;
        }
    }

    ${media.lessThan('768px')`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 35px;

        div{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            :last-child{
                margin-top: 0;
            }
        }  
    `}
`
export const TopBtn = styled.div`
    border: 1px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    right: 60px;
    top: 40px;

    .arrow{
        height: 22px;
        width: 1px;
        background: #fff;
        position: relative;
        border-radius: 5px;

        :after, :before{
            content: "";
            position: absolute;
            width: 1px;
            height: 10px;
            background: #fff;
            top: -1px;
            border-radius: 5px;
        }

        :after{
            transform: rotate(40deg);
            right: 3px;
        }
        :before{
            transform: rotate(-40deg);
            left: 3px;
        }
    }

    ${media.lessThan('768px')`
        right: 20px;
        top: 20px;
    `}
`